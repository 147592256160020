import PropTypes from 'prop-types';
import React from 'react';
import { mdiClipboardTextMultipleOutline, mdiFormatColorFill, mdiStarPlusOutline } from '@mdi/js';
import {
  Col, Form, Input, Row, Space, Switch,
} from 'antd';
import { useTranslation } from 'react-i18next';

import InfoBlock from '../../components/InfoBlock/InfoBlock';
import IconSwitch from './IconSwitch/IconSwitch';
import ColorSwitch from './ColorSwitch/ColorSwitch';
import ListOfActorsTags from '../../actors/components/ListOfActorsTags';
import ActorsModalBtn from '../../actors/components/ActorsModalBtn';

import { capitalize } from '../../mainUtils';
import { COLORS, PublicInterfaceIconsConstants54Origins } from '../../54origins/components/publicInterface54Origins/publicInterfaceIconsConstants54Origins';
import BaseSwitch from '../../_ui/BaseSwitch/BaseSwitch';

function InterfaceForm({
  changeState,
  editMode,
  form,
  interfaceColor,
  interfaceIcon,
  groupsForPI = [],
  onSaveCallBackAndSetLocalActors,
}) {
  const { t } = useTranslation();

  const tMsg = (
    path,
    defaultValue,
  ) => capitalize(
    t(
      `auth.validation.messages.${path}`,
      defaultValue || path,
    ),
  );

  const isRequired = {
    required: true,
    whitespace: true,
    message: 'The field is required and cannot be empty',
  };

  const rules = {
    service_name: [{ min: 1 }, { max: 100 }, isRequired],
    service_domain: [{ type: 'url' }, isRequired],
    backend_service: [isRequired],
    params: [
      () => (
        {
          validator(_, value) {
            try {
              JSON.parse(value);
              return Promise.resolve();
            } catch (e) {
              return Promise.reject(new Error(capitalize(t(
                'auth.headers.json_invalid',
                'JSON format invalid',
              ))));
            }
          },
        }
      ),
    ],
  };

  const validateMessages = {
    required: tMsg('required', 'is required'),
    string: {
      len: tMsg('len', 'must be exactly ${len} characters'),
      min: tMsg('min', 'must be at least ${min} characters'),
      max: tMsg('max', 'cannot be longer than ${max} characters'),
    },
    pattern: {
      mismatch: tMsg('mismatch', 'is not a valid email'),
    },
    types: {
      email: tMsg('email', 'is not a valid email'),
      number: tMsg('number', 'must be a number type'),
      url: tMsg('url', 'is not a valid URL'),
    },
  };

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      form={form}
      validateMessages={validateMessages}
    >
      <InfoBlock
        iconPath={mdiClipboardTextMultipleOutline}
        title={capitalize(t('auth.headers.interface_detail', 'interface detail'))}
      >
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.service_name', 'service name'))}
              name="service_name"
              rules={rules.service_name}
            >
              <Input
                placeholder={capitalize(
                  t('auth.placeholders.enter', {
                    value: '$t(auth.headers.service_name)',
                  }),
                )}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.domain', 'domain'))}
              name="service_domain"
              rules={rules.service_domain}
            >
              <Input
                placeholder={capitalize(
                  t('auth.placeholders.enter', {
                    value: '$t(auth.headers.domain)',
                  }),
                )}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.configuration', 'configuration'))}
              name="params"
              rules={rules.params}
              normalize={(value) => {
                try {
                  return JSON.stringify(JSON.parse(value), null, 4);
                } catch (e) {
                  return value;
                }
              }}
            >
              <Input.TextArea
                placeholder={capitalize(
                  t('auth.placeholders.enter', {
                    value: '$t(auth.headers.configuration)',
                  }),
                )}
                autoSize={{
                  minRows: 3,
                  maxRows: 10,
                }}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col>
            <ListOfActorsTags
              actors={groupsForPI}
            />
            <ActorsModalBtn
              actorType="user"
              modalTitle="Change groups for PI"
              initialActorsUUIDs={groupsForPI.map((item) => item.uuid)}
              listOfActorsActionBtnLabel="add group"
              btnModalLabel="add groups for PI"
              onSaveCallBack={onSaveCallBackAndSetLocalActors}
              doNotMakeRequest={['saveActors']}
                // resetFromOuterComponent={resetValue}
            />
          </Col>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.display_on_main', 'display on main'))}
              className="mb-0"
              name="display_service"
              valuePropName="checked"
            >
              <BaseSwitch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                valuePropName="checked"
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
        </Row>
      </InfoBlock>
      {editMode && (
        <>
          <InfoBlock
            iconPath={mdiStarPlusOutline}
            title={capitalize(t('auth.headers.select_icon', 'select icon'))}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Space size={[8, 8]} wrap>
                  {Object.keys(PublicInterfaceIconsConstants54Origins).map((item) => (
                    <IconSwitch
                      key={`icon_${item}`}
                      icon={item}
                      checked={interfaceIcon === item}
                      onClick={(value) => changeState({ interfaceIcon: value })}
                    />
                  ))}
                </Space>
              </Col>
            </Row>
          </InfoBlock>
          <InfoBlock
            iconPath={mdiFormatColorFill}
            title={capitalize(t('auth.headers.select_color', 'select color'))}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Space size={[8, 8]} wrap>
                  {Object.keys(COLORS).map((item) => (
                    <ColorSwitch
                      key={`color_${item}`}
                      path={interfaceIcon}
                      color={item}
                      checked={interfaceColor === item}
                      onClick={(value) => changeState({ interfaceColor: value })}
                    />
                  ))}
                </Space>
              </Col>
            </Row>
          </InfoBlock>
        </>
      )}
    </Form>
  );
}

export default InterfaceForm;

InterfaceForm.propTypes = {
  changeState: PropTypes.func,
  editMode: PropTypes.bool,
  form: PropTypes.object.isRequired,
  groupsForPI: PropTypes.array,
  interfaceColor: PropTypes.string,
  interfaceIcon: PropTypes.string,
  onSaveCallBackAndSetLocalActors: PropTypes.func,
};
