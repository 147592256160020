import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form, Radio } from 'antd';

const optionsWithDisabled = [
  { label: 'internal', value: true },
  { label: 'external', value: false },
];

function InternalOrExternalSwitcher({
  getSwitchValueCallback,
  className,
}) {
  const [value, setValue] = useState(true);

  const onChange = ({ target: { value } }) => {
    setValue(value);

    getSwitchValueCallback?.(value);
  };

  return (
    <Form.Item
      label="Actor:"
      name="internal"
      className={`flex ${className}`}
    >
      <Radio.Group
        size="small"
        options={optionsWithDisabled}
        onChange={onChange}
        value={value}
        optionType="button"
        buttonStyle="solid"
      />
    </Form.Item>
  );
}

export default InternalOrExternalSwitcher;

InternalOrExternalSwitcher.propTypes = {
  className: PropTypes.string,
  getSwitchValueCallback: PropTypes.func,
};
