import React, { useEffect } from 'react';
import { Button } from 'antd';
import Icon from '@mdi/react';
import { mdiFormatListGroup } from '@mdi/js';

import ListOfActorsTags from './ListOfActorsTags';

import { capitalizeAndTranslateMsg, translateMsg } from '../../mainUtils';
import useAuthActorsRequests from '../hooks/useAuthActorsRequests';
import useCustomState from '../../hooks/useCustomState';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';

function GetActorsAndShowListOfActors({
  hideElements = [],
  actorData,
  doNotMakeRequest = false,
  outerActors,
}) {
  const defaultState = {
    localActors: [],
    lengthOfActors: 0,
  };

  const { requestGetListOfActorsOnAuth } = useAuthActorsRequests();

  const {
    changeState,
    toggle,
    setToggle,
    lengthOfActors,
    localActors,
  } = useCustomState(defaultState);

  const INITIAL_LIMIT_OF_ACTORS = 10;
  const hideCollapse = lengthOfActors < INITIAL_LIMIT_OF_ACTORS;

  const {
    actor_type: actorType,
    uuid: actorUUID,
    uinfo: {
      groups = [],
    } = {},
  } = actorData || {};

  const saveActorsToState = (actors, total) => {
    changeState({
      localActors: actors,
      lengthOfActors: total ?? actors?.length,
    });
  };

  const getActorsRequest = (params) => {
    const data = {
      ...params,
    };

    switch (actorType) {
      case 'user':
      case 'classic_user':
      case 'service':
        if (groups.length !== 0) {
          data.actor_type = ['group'];
          data.uuid = groups;

          return requestGetListOfActorsOnAuth(data, []);
        }
        break;
      case 'group':
        if (actorUUID) {
          data.uinfo = {};
          data.uinfo.groups = [actorUUID];

          return requestGetListOfActorsOnAuth(data, []);
        }

        break;
      default:
        return [];
    }
  };

  const getActorsAndChangeState = async (params) => {
    const actorsData = await getActorsRequest(params);

    const {
      actors = [],
      total = 0,
    } = actorsData || {};

    saveActorsToState(actors, total);
  };

  const initFunc = () => {
    if (!doNotMakeRequest) {
      const params = {
        limit: INITIAL_LIMIT_OF_ACTORS,
        offset: 0,
      };

      getActorsAndChangeState(params);
    }
  };

  const changeCollapse = () => {
    if (toggle) {
      initFunc();
    } else {
      getActorsAndChangeState();
    }

    setToggle();
  };

  useEffect(() => {
    initFunc();
  }, [
    actorUUID,
    JSON.stringify(groups),
  ]);

  useEffect(() => {
    if (outerActors) {
      saveActorsToState(outerActors);
    }
  }, [JSON.stringify(outerActors)]);

  return (
    <>
      <ListOfActorsTags
        actors={localActors}
      />
      <div className="flex mt-4">
        {(!hideCollapse && !hideElements.includes('collapse')) && (
        <BaseButton
          onClick={changeCollapse}
          type="default"
          size="small"
          className="button-primary-outlined mr-4"
        >
          <BaseIcon path={mdiFormatListGroup} size={1} />
          {capitalizeAndTranslateMsg('54origins.verb.show', 'Show')}
          {' '}
          {toggle
            ? `${translateMsg('54origins.adverb.only', 'only')} ${INITIAL_LIMIT_OF_ACTORS}`
            : `${translateMsg('54origins.noun.all')} ${lengthOfActors}`}
          {' '}
          groups
        </BaseButton>
        )}

      </div>
    </>
  );
}

export default GetActorsAndShowListOfActors;
