import React from 'react';
import { Switch } from 'antd';
import cn from 'classnames';
import './BaseSwitch.scss';
import PropTypes from 'prop-types';

function BaseSwitch({ className, ...props }) {
  return (
    <Switch
      className={cn('switch-primary', className)}
      {...props}
    />
  );
}

export default BaseSwitch;

BaseSwitch.propTypes = {
  className: PropTypes.string,
};
