import React, { memo } from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';

import './Header.scss';

import LocaleSwitcher from '../LocaleSwitcher';
import ProfileMenu from '../ProfileMenu';
import MasqueradingTurnOffButton from '../MasqueradingTurnOffButton';
import InterfacesListModal54Origins
  from '../../54origins/components/publicInterface54Origins/InterfacesListModal54Origins';

import { urlAPI } from '../../api/api';

function Header() {
  return (
    <header className="dt-header">
      <div className="dt-header__container">
        <div className="dt-brand">
          <span className="dt-brand__logo">
            <Link className="dt-brand__logo-link" to="/profile">
              Auth service
            </Link>
          </span>
        </div>
        <Row className="flex items-stretch justify-end w-full">
          <Col>
            <MasqueradingTurnOffButton />
          </Col>
          <Col>
            <InterfacesListModal54Origins
              url={urlAPI}
              sessionTokenName="Auth"
              className="h-full"
            />
          </Col>
          <Col>
            <LocaleSwitcher />
          </Col>
          <Col>
            <ProfileMenu />
          </Col>
        </Row>
      </div>
    </header>
  );
}

export default memo(Header);
