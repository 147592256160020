import PropTypes from 'prop-types';
import React, { useContext, useReducer } from 'react';
import { Button, Popconfirm } from 'antd';
import Icon from '@mdi/react';
import { mdiReload, mdiTrashCan, mdiTrashCanOutline } from '@mdi/js';
import { ApiContext } from '../../api/ApiContextProvider';
import { antNotification, capitalizeAndTranslateMsg } from '../../mainUtils';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';

function ActionBtnUnSyncChanges({
  packageID,
  syncPackageCallback,
  className,
  typeOfAction,
}) {
  const { requestSynchronizePackage } = useContext(ApiContext);

  const [confirmFlag, setConfirmFlag] = useReducer((state) => !state, false);

  const deleteMode = typeOfAction === 'delete';

  const syncPackage = () => {
    requestSynchronizePackage(packageID, deleteMode).then((res) => {
      const { message } = res || {};
      antNotification.success(message);

      syncPackageCallback?.(200, packageID, typeOfAction);
    }).catch(() => {
      syncPackageCallback?.(500, packageID, typeOfAction);
    });

    setConfirmFlag();
  };

  return (
    <Popconfirm
      open={confirmFlag}
      title="Are you sure?"
      onConfirm={syncPackage}
      onCancel={setConfirmFlag}
      okText="Yes"
      cancelText="No"
    >
      <BaseButton
        onClick={setConfirmFlag}
        size="default"
        type={deleteMode ? 'danger' : 'primary'}
        className={className}
      >
        {capitalizeAndTranslateMsg(`54origins.verb.${deleteMode ? 'delete' : 'retry'}`)}
        <BaseIcon
          path={deleteMode ? mdiTrashCanOutline : mdiReload}
          className="ml-2"
          size={1}
        />
      </BaseButton>
    </Popconfirm>

  );
}

export default ActionBtnUnSyncChanges;

ActionBtnUnSyncChanges.propTypes = {
  packageID: PropTypes.number,
  syncPackageCallback: PropTypes.func,
  typeOfAction: PropTypes.string,
  className: PropTypes.string,
};
