/* eslint-disable new-cap */
// noinspection JSPotentiallyInvalidConstructorUsage,JSUnresolvedFunction

import PropTypes from 'prop-types';
import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import { useTranslation } from 'react-i18next';
import {
  Button, Card, Col, Input, Row, Space, Spin,
} from 'antd';
import Icon from '@mdi/react';
import {
  mdiClose, mdiContentSaveOutline, mdiSquareEditOutline, mdiTrayArrowDown,
} from '@mdi/js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useSelector } from 'react-redux';
import PolicyPreviewFile from './PolicyPreviewFile';
import { capitalize } from '../../mainUtils';
import { getPolicyFetching } from '../selectors';
import BaseCard from '../../_ui/BaseCard/BaseCard';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';

function PolicyEditFile({
  documentData = '',
  documentName,
  onChangeDocument,
  onChangeDocumentName,
  editMode,
  onCancelEdit,
  onUpdatePolicy,
  toggleEditMode,
}) {
  const { t } = useTranslation();

  const policyFetching = useSelector(getPolicyFetching);

  const saveAsPDF = async () => {
    const mdPreview = document.querySelector('.wmde-markdown');

    if (mdPreview) {
      mdPreview.classList.add('p-4');
      await html2canvas(mdPreview)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'JPEG', 0, 0);
          pdf.save(`${documentName || 'policy'}.pdf`);
        });
      mdPreview.classList.remove('p-4');
    }
  };

  return (
    <Spin spinning={policyFetching}>
      <BaseCard className="border-blue">
        <Row>
          <Col flex="none">
            <h4 className="header-primary">
              {capitalize(t('auth.headers.policy_description', 'policy description'))}
            </h4>
          </Col>
          <Col
            flex="auto"
            className="flex justify-end"
          >
            <Space
              size={[5, 1]}
              wrap
              className="justify-end"
            >
              {editMode ? (
                <>
                  <BaseButton
                    className="button-secondary-outlined"
                    size="small"
                    onClick={onCancelEdit}
                  >
                    <BaseIcon path={mdiClose} size={1} className="mr-1" />
                    {capitalize(t('auth.buttons.cancel', 'cancel'))}
                  </BaseButton>
                  <BaseButton
                    className="button-primary"
                    size="small"
                    onClick={onUpdatePolicy}
                  >
                    <BaseIcon path={mdiContentSaveOutline} size={1} className="mr-1" />
                    {capitalize(t('auth.buttons.save', 'save'))}
                  </BaseButton>
                </>
              ) : (
                <>
                  <BaseButton
                    className="button-primary-outlined"
                    size="small"
                    onClick={toggleEditMode}
                  >
                    <BaseIcon path={mdiSquareEditOutline} size={1} className="mr-1" />
                    {capitalize(t('auth.buttons.edit', 'edit'))}
                  </BaseButton>
                  <BaseButton
                    className="button-primary"
                    size="small"
                    onClick={saveAsPDF}
                  >
                    <BaseIcon path={mdiTrayArrowDown} size={1} className="mr-1" />
                    {capitalize(t('auth.buttons.save_pdf', 'save .PDF'))}
                  </BaseButton>
                </>
              )}
            </Space>
          </Col>
        </Row>
        <hr className="my-6" />
        <Row>
          <Col span={24}>
            {editMode ? (
              <>
                <h5 className="header-primary mb-2">
                  {capitalize(t('auth.headers.policy_name', 'document name'))}
                  :
                </h5>
                <Input
                  onChange={(e) => onChangeDocumentName(e.target.value)}
                  type="text"
                  value={documentName}
                  className="mb-4"
                  placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.name') }))}
                />
                <h5 className="header-primary my-2">
                  {capitalize(t('auth.headers.policy_text', 'content'))}
                  :
                </h5>
                <MDEditor
                  value={documentData}
                  onChange={onChangeDocument}
                  preview="edit"
                  height="auto"
                  visiableDragbar={false}
                  tabSize={2}
                  enableScroll={false}
                  extraCommands={[]}
                />
              </>
            ) : (
              <PolicyPreviewFile documentData={documentData} />
            )}
          </Col>
        </Row>
      </BaseCard>
    </Spin>
  );
}

export default PolicyEditFile;

PolicyEditFile.propTypes = {
  documentData: PropTypes.string,
  documentName: PropTypes.string,
  editMode: PropTypes.bool,
  onCancelEdit: PropTypes.func.isRequired,
  onChangeDocument: PropTypes.func.isRequired,
  onChangeDocumentName: PropTypes.func.isRequired,
  onUpdatePolicy: PropTypes.func.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
};
