import React, { memo } from 'react';
import { Button } from 'antd';
import Icon from '@mdi/react';
import BaseIcon from '../_ui/BaseIcon/BaseIcon';
import BaseButton from '../_ui/BaseButton/BaseButton';

const AntButtonWithMDI = memo(({
  className,
  disabled,
  type,
  pathForMdi,
  sizeMdi = 1,
  label,
  onClick,
  iconClassName,
  size,
}) =>
  // console.log('RENDER ANTBTN', label);
  (
    <BaseButton
      type={type}
      className={className}
      size={size}
      onClick={onClick}
      disabled={disabled}
      icon={pathForMdi && (
      <BaseIcon
        className={iconClassName}
        path={pathForMdi}
        size={sizeMdi}
      />
      )}
    >
      {label}
    </BaseButton>
  ));

export default AntButtonWithMDI;
