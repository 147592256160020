import PropTypes from 'prop-types';
import React from 'react';
import {
  Alert,
  Button,
  Col, Empty, Row, Table,
} from 'antd';
import { useSelector } from 'react-redux';
import Icon from '@mdi/react';

import { mdiArrowRight, mdiReload } from '@mdi/js';
import { isArray, last } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import TagForLogComponent from './TagForLogComponent';
import ActionBtnUnSyncChanges from './ActionBtnUnSyncChanges';

import { getNameForActor } from '../../54origins/utils54origins';
import { getUnSyncGroupsDataMap } from '../../actors/reducers/actorsMapReducer';
import { capitalize } from '../../mainUtils';
import { getLocale } from '../../locale/selectors';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';

function ActorListOfLogsComponent({
  listOfLogs = [],
  syncPackageCallback,
  reloadUnSyncChangesCallback,
}) {
  dayjs.extend(utc);

  const logsData = listOfLogs[0]?.logs || [];

  const locale = useSelector(getLocale) || 'en';
  const unSyncGroupsDataMap = useSelector(getUnSyncGroupsDataMap);

  const toFlatPropertyMap = (obj, keySeparator = '.') => {
    const flattenRecursive = (obj, parentProperty, propertyMap = {}) => {
      for (const [key, value] of Object.entries(obj)) {
        const property = parentProperty ? `${parentProperty}${keySeparator}${key}` : key;
        if (value && typeof value === 'object' && !Array.isArray(value)) {
          flattenRecursive(value, property, propertyMap);
        } else {
          propertyMap[property] = value;
        }
      }
      return propertyMap;
    };
    return flattenRecursive(obj);
  };

  const newColumns = [
    {
      title: 'Action',
      dataIndex: 'action',
      render: (cell) => <TagForLogComponent typeOfTag={cell} />,
    },
    {
      title: 'Changes',
      dataIndex: 'current_delta',
      render: (cell, row) => {
        const {
          current_delta,
          previous_delta,
          retry = false,
        } = row;

        const getArrayOfChanges = () => {
          const flattenCurr = toFlatPropertyMap(current_delta);
          const flattenPrev = toFlatPropertyMap(previous_delta);
          const arr = [];

          const saveToArr = (field, value) => arr.push({
            field,
            prevValue: flattenPrev[value],
            currValue: flattenCurr[value],
          });

          const checkIfFieldIsPasswordOrSaveToArr = (field) => {
            if (field.indexOf('password') !== -1) {
              saveToArr('password', 'changed');
            } else {
              saveToArr(field, field);
            }
          };

          for (const currKey of Object.keys(flattenCurr)) {
            checkIfFieldIsPasswordOrSaveToArr(currKey);
            delete flattenPrev[currKey];
          }

          for (const prevKey of Object.keys(flattenPrev)) {
            checkIfFieldIsPasswordOrSaveToArr(prevKey);
          }

          return arr;
        };

        const checkIsArrayAndGetValue = (value) => {
          if (isArray(value)) {
            return value.map((item) => <p className="m-1">{getNameForActor(unSyncGroupsDataMap.get(item))}</p>);
          }
          return value;
        };

        const checkIfKeyIsActorUUID = (key) => {
          const lastKey = last(key.split('.'));

          if (lastKey.length === 36) {
            return getNameForActor(unSyncGroupsDataMap.get(lastKey));
          }
          return lastKey;
        };

        return (
          <>
            {getArrayOfChanges().map((item, key) => {
              const { field, prevValue, currValue } = item;

              return (
                <>
                  {retry ? (
                    <Row>
                      <Col>
                        <Alert message="Package is in the process of being synchronized" type="info" />
                      </Col>
                    </Row>
                  ) : (
                    <Row key={`${field}${key}`}>
                      <Col
                        span={7}
                        className="px-3 py-2"
                        style={{
                          backgroundColor: '#f1f1f1',
                          border: '1px solid #e6e6e6',
                          color: 'black',
                        }}
                      >
                        {checkIfKeyIsActorUUID(field)}
                      </Col>
                      <Col
                        className="bg-red px-3 py-2"
                        span={7}
                      >
                        {checkIsArrayAndGetValue(prevValue)}
                      </Col>
                      <Col
                        span={2}
                        className="flex items-center justify-center"
                      >
                        <BaseIcon path={mdiArrowRight} size={1} />
                      </Col>
                      <Col
                        span={7}
                        className="bg-green px-3 py-2"
                      >
                        {checkIsArrayAndGetValue(currValue)}
                      </Col>
                    </Row>
                  )}
                </>
              );
            })}
          </>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'created',
      render: (cell) => capitalize(dayjs.utc(cell)
        .local()
        .locale(locale)
        .format('MMM D, h:mm A')),
    },
    {
      title: '',
      dataIndex: 'package_id',
      render: (cell) => (
        <div className="flex">
          <ActionBtnUnSyncChanges
            syncPackageCallback={syncPackageCallback}
            packageID={cell}
          />
          <ActionBtnUnSyncChanges
            syncPackageCallback={syncPackageCallback}
            packageID={cell}
            className="ml-2"
            typeOfAction="delete"
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {logsData?.length > 0 ? (
        <Table
          size="small"
          columns={newColumns}
          dataSource={logsData}
        />
      ) : (
        <Empty
          description="Unsynchronized changes is empty"
        >
          <BaseButton
            type="primary"
            onClick={reloadUnSyncChangesCallback}
          >
            Reload unsync changes
            <BaseIcon path={mdiReload} className="ml-2" size={1} />
          </BaseButton>
        </Empty>
      )}
    </>
  );
}

export default ActorListOfLogsComponent;

ActorListOfLogsComponent.propTypes = {
  listOfLogs: PropTypes.array,
  syncPackageCallback: PropTypes.func,
  reloadUnSyncChangesCallback: PropTypes.func,
};
