import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Outlet,
} from 'react-router-dom';

import Header from '../components/Header/Header';
import SidebarComponent from '../components/SidebarComponent/SidebarComponent';
import FooterComponent from '../components/Footer/Footer';

import { getAccess, isProfileFetching } from '../profile/selectors';
import { AuthHelper } from '../auth/utils';
import { ApiContext } from '../api/ApiContextProvider';
import { ActorsConstants } from '../actors/constants/actionTypes';
import useURLParams from "../hooks/useURLParams";
import './MainLayout.scss';

function MainLayout() {
  const access = useSelector(getAccess);
  const isFetching = useSelector(isProfileFetching);
  const {currentLocationPathname, currentLocation} = useURLParams()

  const {
    requestGetProfile, requestGetMyPermissions, requestAbout, requestGetAllGroups,
  } = useContext(ApiContext);

  const pathname = window.location.href;

  if (pathname.includes('?identifier') || pathname.includes('&identifier')) {
    localStorage.setItem('identifier', pathname.split('identifier=')[1]);
  }

  useEffect(() => {
    AuthHelper.removeSession();

    if (AuthHelper.isAuthorised()) {
      requestGetProfile();
      requestGetMyPermissions();
      requestAbout();

      const defaultGroupsNames = { uinfo: { group_name: ['ADMIN', 'DEFAULT', 'BAN'] } };
      const defaultGroupsConstants = [
        ActorsConstants.GET_DEFAULT_GROUPS_REQUEST,
        ActorsConstants.GET_DEFAULT_GROUPS_SUCCESS,
        ActorsConstants.GET_DEFAULT_GROUPS_FAILURE,
      ];

      requestGetAllGroups(defaultGroupsNames, defaultGroupsConstants);
    }
  }, [AuthHelper.isAuthorised(), access]);


  // useEffect(() => {
  //   console.log('prev', localStorage.getItem('location'))
  //   console.log('currentLocation', currentLocation)
  //   localStorage.setItem('location', currentLocation)
  //
  // }, [JSON.stringify(currentLocation)])

  return (
    <div className="dt-root">
      {AuthHelper.isAuthorised() ? <Header /> : null}
      <main className={`${AuthHelper.isAuthorised() ? 'dt-main' : 'mt-0'}`}>
        {AuthHelper.isAuthorised() ? <SidebarComponent isFetching={isFetching} /> : null}
        <div className="dt-content-wrapper">
          <div className="dt-content">
            <Outlet />
          </div>
        </div>
      </main>
      {AuthHelper.isAuthorised() ? <FooterComponent /> : null}
    </div>
  );
}

export default MainLayout;
