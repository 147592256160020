import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Alert, Button, Input, Modal,
} from 'antd';
import Icon from '@mdi/react';
import { mdiClose, mdiCodeJson } from '@mdi/js';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { capitalize, textToClipboard } from '../../mainUtils';
import { getAllGroups, getAllServices } from '../../actors/selectors';
import { getInterfacesList } from '../../publicInterfaces/selectors';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';

function PolicyExportJSON({
  documentName,
  documentData,
}) {
  const { t } = useTranslation();

  const allServices = useSelector(getAllServices);
  const allGroups = useSelector(getAllGroups);
  const allInterfaces = useSelector(getInterfacesList);

  const [policyData, setPolicyData] = useState({});
  const [policyJSON, setPolicyJSON] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [validJSON, setValidJSON] = useState(true);

  const toggleModal = () => setModalVisible((prev) => !prev);

  const policyToJSON = (policy) => {
    const json = JSON.stringify(policy, null, 4);
    return json === '{}' ? '' : json;
  };

  const onChange = (e) => {
    const { value } = e.target;

    if (value) {
      try {
        JSON.parse(value);
        setValidJSON(true);
      } catch {
        setValidJSON(false);
      }
    } else {
      setValidJSON(true);
    }

    setPolicyJSON(value);
  };

  const onCopy = () => {
    textToClipboard(policyJSON);
    setValidJSON(true);
    toggleModal();
  };

  const onCancel = () => {
    setPolicyJSON(policyToJSON(policyData));
    setValidJSON(true);
    toggleModal();
  };

  const initFunc = () => {
    const initPolicy = {
      policy: {
        security_policy_name: documentName,
        security_policy_text: documentData,
      },
      public_interfaces: allInterfaces,
      actors: {
        services: allServices,
        groups: allGroups,
      },
    };

    setPolicyData(initPolicy);
    setPolicyJSON(policyToJSON(initPolicy));
  };

  useEffect(() => {
    initFunc();
  }, [allServices.length, allGroups.length, allInterfaces.length, documentName]);

  return (
    <>
      <BaseButton
        className="button-primary-outlined"
        size="small"
        onClick={toggleModal}
      >
        <BaseIcon path={mdiCodeJson} size={1} className="mr-1" />
        JSON
      </BaseButton>
      <Modal
        title={`JSON ${capitalize(t('auth.pages.security_policy', 'security policy'))}`}
        open={modalVisible}
        onOk={onCopy}
        onCancel={onCancel}
        width={720}
        destroyOnClose
        closeIcon={<BaseIcon path={mdiClose} size={1.5} />}
        footer={[
          <BaseButton
            key="cancel"
            className="button-secondary-outlined"
            onClick={onCancel}
          >
            {capitalize(t('auth.buttons.cancel', 'cancel'))}
          </BaseButton>,
          <BaseButton
            key="copy"
            className="button-primary-outlined"
            onClick={onCopy}
            disabled={!policyJSON || !validJSON}
          >
            {capitalize(t('auth.buttons.copy', 'copy'))}
          </BaseButton>,
          <BaseButton
            key="set"
            className="button-primary"
            disabled={policyToJSON(policyData) === policyJSON || !validJSON}
            // onClick={onSetPerms}
          >
            {capitalize(t('auth.buttons.set', 'set'))}
          </BaseButton>,
        ]}
        okButtonProps={{
          className: 'button-primary',
        }}
        cancelButtonProps={{
          className: 'button-secondary-outlined',
        }}
      >
        {!validJSON && (
          <Alert
            className="mb-4"
            message={capitalize(t('auth.headers.error', 'error')).toUpperCase()}
            description={capitalize(t('auth.headers.json_invalid', 'JSON format invalid'))}
            type="error"
            showIcon
          />
        )}
        {modalVisible && (
          <Input.TextArea
            value={policyJSON}
            onChange={onChange}
            rows={16}
            autoFocus
          />
        )}
      </Modal>
    </>
  );
}

export default PolicyExportJSON;

PolicyExportJSON.propTypes = {
  documentData: PropTypes.string,
  documentName: PropTypes.string,
};
