// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2023.01.29 //Format YYYY|MM|DD
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

const { Text } = Typography;

function DateLabelFormat54origins({
  date,
  textType,
  format = 'DD-MM-YYYY',
  labelBeforeDate,
  className,
}) {
  return (
    <Text
      type={textType}
      className={className}
    >
      {labelBeforeDate && `${capitalize(labelBeforeDate)} `}
      {dayjs(date).format(format)}
    </Text>
  );
}

export default memo(DateLabelFormat54origins);

DateLabelFormat54origins.propTypes = {
  className: PropTypes.string,
  date: PropTypes.any,
  format: PropTypes.string,
  labelBeforeDate: PropTypes.string,
  textType: PropTypes.string,
};
