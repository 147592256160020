import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiAutorenew } from '@mdi/js';
import React from 'react';
import { Button } from 'antd';
import BaseIcon from '../../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../../_ui/BaseButton/BaseButton';

function GeneratePassBtn({
  onClickCallBack,
}) {
  const generatePass = () => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%&*ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const passwordLength = 12;
    let password = '';
    for (let i = 0; i <= passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password;
  };

  const generatePassAndCallBack = () => {
    onClickCallBack?.(generatePass());
  };

  return (
    <BaseButton
      size="small"
      className="btnPrimary-outline"
      onClick={generatePassAndCallBack}
      icon={<BaseIcon className="mr-1" path={mdiAutorenew} size={0.8} />}
    >
      generate pass
    </BaseButton>
  );
}

export default GeneratePassBtn;

GeneratePassBtn.propTypes = {
  onClickCallBack: PropTypes.func,
};
