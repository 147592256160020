import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import './BaseButton.scss';

function BaseButton({ children, ...props }) {
  return (
    <Button
      {...props}
    >
      {children}
    </Button>
  );
}

export default BaseButton;

BaseButton.propTypes = {
  children: PropTypes.node,
};
