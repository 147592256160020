import React from 'react';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import './InfoBlock.scss';

function InfoBlock({
  children,
  title = '',
  iconPath,
  className = '',
}) {
  return (
    <Row gutter={[8, 8]} wrap={false} className={`info-block ${className}`}>
      <Col>
        {iconPath
          && (
          <BaseIcon
            path={iconPath}
            size={1.4}
            className="info-block-icon"
          />
          )}
      </Col>
      <Col flex="auto">
        <p className="info-block-header">
          {title}
          :
        </p>
        <div className="info-block-content">
          {children}
        </div>
      </Col>
    </Row>
  );
}

InfoBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  className: PropTypes.string,
  iconPath: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default InfoBlock;
