import React, { useMemo } from 'react';
import { Col, Switch, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AntTable from '../../components/AntTable';

import { capitalize } from '../../mainUtils';
import { getServiceAdmin, getServiceAdminFetching } from '../slicers/adminServicePermSlicer';
import BaseSwitch from '../../_ui/BaseSwitch/BaseSwitch';

function AdminServicePerm({
  changeServiceAdmin,
}) {
  const { t } = useTranslation();

  const serviceAdmin = useSelector(getServiceAdmin);
  const serviceAdminFetching = useSelector(getServiceAdminFetching);

  const onConfirm = () => {
    changeServiceAdmin();
  };

  const getTitleForPopConfirm = useMemo(() => {
    if (serviceAdmin) {
      return capitalize(t('auth.messages.take_away_the_admin_rights_on_this_service', 'take away the admin rights'));
    }
    return capitalize(t('auth.messages.make_the_actor_an_admin_on_this_service', 'make the actor an admin'));
  }, [serviceAdmin]);

  const formatSwitchValue = (value) => (
    <Popconfirm
      placement="right"
      title={getTitleForPopConfirm}
      onConfirm={onConfirm}
      okText={capitalize(t('auth.buttons.yes', 'yes'))}
      cancelText={capitalize(t('auth.buttons.no', 'no'))}
    >
      <BaseSwitch
        checkedChildren={capitalize(t('auth.buttons.allowed', 'Allowed'))}
        unCheckedChildren={capitalize(t('auth.buttons.denied', 'Denied'))}
        checked={value}
        loading={serviceAdminFetching}
      />
    </Popconfirm>
  );

  const columns = [
    {
      dataIndex: 'title',
      key: 'title',
      title: capitalize(t('auth.headers.title', 'title')),
      // className: 'ant-table-column-has-sorters'
    },
    {
      dataIndex: 'value',
      key: 'value',
      title: capitalize(t('auth.headers.value', 'value')),
      render: formatSwitchValue,
      // className: 'ant-table-column-has-sorters'
    }];

  const data = [
    {
      title: capitalize(t('auth.messages.is_admin_on_this_service', 'is admin on this service')),
      value: serviceAdmin,
    },
  ];

  return (
    <Col
      span={24}
      className="perms-admin"
    >
      <h4 className="perms-title">
        {capitalize(t('auth.headers.admin', 'admin'))}
      </h4>
      <AntTable
        data={data}
        columns={columns}
        disablePagination
      />
    </Col>
  );
}

export default AdminServicePerm;
