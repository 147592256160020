import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button, Card, Col, Input, Row, Space, Tag,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiPlusCircle, mdiTrashCanOutline } from '@mdi/js';
import _ from 'lodash';
import DeleteButton from '../../../components/DeleteButton';
import AntTable from '../../../components/AntTable';
import { capitalize } from '../../../mainUtils';
import BaseCard from '../../../_ui/BaseCard/BaseCard';
import BaseIcon from '../../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../../_ui/BaseButton/BaseButton';
import BaseTag from '../../../_ui/BaseTag/BaseTag';
import './CertificatesList.scss';

function CertificatesList({
  certificates,
  afterDeleteCertificate,
  isCreateView,
  switchView,
  selectedCertificateID,
}) {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');

  const formatDomain = (cell) => (
    <Space size={[4, 4]} wrap>
      {cell
        .split('\n')
        .filter((domain) => !!domain)
        .map((domain, i) => (
          <BaseTag
            key={`${domain}_${i}`}
            className="tag-gray-light"
          >
            {domain}
          </BaseTag>
        ))}
    </Space>
  );

  const formatActions = (id) => (
    <div className="flex">
      <DeleteButton
        targetType="certificate"
        onSuccess={afterDeleteCertificate}
        data={{ id }}
      >
        <BaseButton
          className="button-danger-outlined px-2"
          size="small"
        >
          <BaseIcon path={mdiTrashCanOutline} size={1} />
        </BaseButton>
      </DeleteButton>
    </div>
  );

  const columns = [
    {
      dataIndex: 'id',
      key: 'id',
      className: 'p-2',
      title: capitalize(t('auth.headers.id', 'id')),
      render: (cell) => <div className="flex flex-nowrap">{cell}</div>,
    },
    {
      dataIndex: 'domains',
      key: 'domains',
      className: 'p-2',
      title: capitalize(t('auth.headers.domains', 'domains')),
      render: formatDomain,
    },
    {
      dataIndex: 'service_uuid',
      key: 'service_uuid',
      className: 'p-2',
      title: capitalize(t('auth.headers.service_uuid', 'service uuid')),
      render: (cell) => <div className="col-service-uuid">{cell}</div>,
    },
    {
      dataIndex: 'certificate',
      key: 'certificate',
      className: 'p-2 ',
      title: capitalize(t('auth.headers.certificate', 'certificate')),
      render: (cell) => <div className=" col-certificate">{cell}</div>,
    },

    {
      dataIndex: 'key',
      key: 'actions',
      className: 'p-2',
      title: capitalize(t('auth.headers.action', 'action')),
      render: formatActions,
    },
  ];

  const rowSelection = {
    // columnWidth: '100%',
    selectedRowKeys: selectedCertificateID ? [selectedCertificateID] : [],
  };

  const onRow = (row) => ({
    onClick: (e) => {
      e.stopPropagation();
      if (selectedCertificateID === _.toString(row.id)) {
        switchView();
      } else {
        switchView('info', _.toString(row.id));
      }
    },
  });

  const getDataTable = () => {
    const copyOfData = [...certificates];

    if (searchValue) {
      return copyOfData.filter(({ domains }) => domains.includes(searchValue.toString()));
    }

    return copyOfData.sort((a, b) => a.id - b.id);
  };

  return (
    <BaseCard className="border-blue">
      <Row gutter={[10, 10]}>
        <Col className="flex items-center">
          <h4 className="header-primary mb-0">
            {capitalize(t('auth.headers.certificates_list', 'certificates list'))}
          </h4>
        </Col>
        <Col span={8}>
          <Input.Search
            allowClear
            onSearch={setSearchValue}
            className="w-full"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={capitalize(t('auth.placeholders.search_certificate', 'enter certificate domain'))}
          />
        </Col>
        <Col
          className="flex ml-auto flex-wrap justify-end"
        >
          <BaseButton
            className={isCreateView ? 'button-warning-outlined' : 'button-primary-outlined'}
            onClick={() => switchView(isCreateView ? null : 'create')}
          >
            <BaseIcon
              path={mdiPlusCircle}
              size={1.5}
              className="slow mr-2"
              rotate={isCreateView ? 765 : 0}
            />
            {isCreateView ? (
              capitalize(t('auth.buttons.cancel', 'cancel'))
            ) : (
              capitalize(t('auth.buttons.create_certificate', 'create certificate'))
            )}
          </BaseButton>
        </Col>
      </Row>
      <hr className="my-6" />
      <Row>
        <Col span={24}>
          <AntTable
            data={getDataTable()}
            rowClassName="table-selectable"
            columns={columns}
            rowKey="id"
            onRow={onRow}
            rowSelection={rowSelection}
          />
        </Col>
      </Row>
    </BaseCard>
  );
}

export default CertificatesList;

CertificatesList.propTypes = {
  afterDeleteCertificate: PropTypes.func.isRequired,
  certificates: PropTypes.array,
  isCreateView: PropTypes.bool,
  selectedCertificateID: PropTypes.string,
  switchView: PropTypes.func.isRequired,
};
