import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TagsSettingList from '../../components/TagsSettingList/TagsSettingList';
import { antNotification, capitalize } from '../../mainUtils';
import { ApiContext } from '../../api/ApiContextProvider';

function FilterLogs({
  setLogsData,
}) {
  const { t } = useTranslation();
  const { requestGetLogs } = useContext(ApiContext);

  const updateBtnData = [
    {
      key: 'actor_type',
      value: 'update',
      label: capitalize(t('auth.headers.updated', 'updated')),
    },
  ];

  const [selectedFilters, setSelectedFilters] = useState(updateBtnData);

  const allTags = [
    {
      tags: [
        {
          key: 'all',
          value: 'all',
          label: capitalize(t('auth.headers.all_actions', 'all actions')),
        },
      ],
      isReset: true,
    },
    {
      tags: [
        {
          key: 'actor_type',
          value: 'create',
          label: capitalize(t('auth.headers.created', 'created')),
        },
        ...updateBtnData,
        {
          key: 'actor_type',
          value: 'delete',
          // status: 'none',
          label: capitalize(t('auth.headers.deleted', 'deleted')),
        },
      ],
    },
  ];

  const getLogsWithFilters = () => {
    const data = selectedFilters.reduce((acc, item) => [...acc, item.value], []);

    const params = {
      action: data,
      order_by: 'created',
      reverse: true,
    };

    requestGetLogs(params)
      .then(({ data }) => setLogsData(data))
      .catch(() => antNotification.error(capitalize(t(
        'auth.notifications.logs_get_failed',
        'error getting logs',
      ))));
  };

  useEffect(() => {
    getLogsWithFilters();
  }, [JSON.stringify(selectedFilters)]);

  return (
    <TagsSettingList
      defaultSelectedTags={updateBtnData}
      allTags={allTags}
      onSelectTags={setSelectedFilters}
      id="allActors"
      small
    />
  );
}

FilterLogs.propTypes = {
  setLogsData: PropTypes.func,
};

export default FilterLogs;
