import React, { useEffect, useState } from 'react';
import {
  Col, Dropdown, Row,
} from 'antd';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  mdiAccountCircleOutline, mdiGhostOutline, mdiLogoutVariant, mdiMenuDown,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UserAvatar from './UserAvatar/UserAvatar';
import MasqueradingModal from './MasqueradingModal';
import ActorListOfTags from '../actors/ActorListOfTags';

import {
  getMeIsRootOrAdmin, getMyPermissions, getProfileInfo,
} from '../profile/selectors';
import { logout } from '../actors/actions/actorsActions';
import { capitalize } from '../mainUtils';
import { getAllUsersMap } from '../actors/selectors';
import { getNameForActor } from '../54origins/utils54origins';
import BaseIcon from '../_ui/BaseIcon/BaseIcon';

function ProfileMenu() {
  const { t } = useTranslation();

  const profileInfo = useSelector(getProfileInfo);
  const myPermissions = useSelector(getMyPermissions);
  const allUsersMap = useSelector(getAllUsersMap);
  const meIsRootOrAdmin = useSelector(getMeIsRootOrAdmin);

  const [modalVisible, setModalVisible] = useState(false);
  const [masqueradedUsers, setMasqueradedUsers] = useState([]);
  const [masqueradingAvailable, setMasqueradingAvailable] = useState(false);

  const profileActor = profileInfo?.actor || {};

  const {
    uinfo: {
      first_name,
      last_name,
    } = {},
    uuid: profileUUID,
  } = profileActor || {};

  const toggleModal = () => setModalVisible((prev) => !prev);

  const getUserName = (uuid) => {
    if (allUsersMap.has(uuid)) {
      const userData = allUsersMap.get(uuid);

      return getNameForActor(userData);
    }
    return '';
  };

  const initFunc = () => {
    if (meIsRootOrAdmin) {
      return;
    }

    const { actor = [], group = [] } = myPermissions || {};
    const permissions = [...actor, ...group.sort((a, b) => b.weight - a.weight)];
    const permissionIndex = _.findIndex(permissions, ['permaction_uuid', '43204251-47fe-46c5-8277-e2ddac0451c4']);

    if (permissionIndex !== -1) {
      const uuids = _.get(permissions, `[${permissionIndex}].params.masquerade`, []);
      const personalInfo = _.get(permissions, `[${permissionIndex}].params.personal_info`, []);
      const personalInfoMap = new Map([
        ...personalInfo.map((item) => [
          _.get(item, 'uuid', ''),
          getNameForActor(item),
        ]),
      ]);

      setMasqueradedUsers(uuids.map((uuid) => (
        {
          value: uuid,
          label: personalInfoMap.get(uuid) || getUserName(uuid) || uuid,
        }
      )));

      setMasqueradingAvailable(true);
    } else {
      setMasqueradingAvailable(false);
    }
  };

  useEffect(() => {
    initFunc();
  }, [JSON.stringify(myPermissions)]);

  const items = [
    {
      label: (
        <Link to="/admin/profile">
          <BaseIcon path={mdiAccountCircleOutline} size={1.5} className="inline mr-4" />
          {capitalize(t('auth.headers.profile', 'profile'))}
          <ActorListOfTags actor={profileActor} className="mt-2" />
        </Link>
      ),
      key: 'profile',
    },
    (masqueradingAvailable && {
      label: (
        <div
          className="header-dropdown-item"
          onClick={toggleModal}
        >
          <BaseIcon path={mdiGhostOutline} size={1.5} className="inline mr-4" />
          {capitalize(t('auth.headers.masquerading', 'masquerading'))}
        </div>
      ),
      key: 'item-2',
    }),
    {
      label: (
        <div onClick={logout} className="item-red">
          <BaseIcon path={mdiLogoutVariant} size={1.5} className="inline mr-4" />
          {capitalize(t('auth.headers.log_out', 'log out'))}
        </div>
      ),
      key: 'logout',
    },
  ];

  return (
    <div className="header-dropdown-wrapper h-full">
      <MasqueradingModal
        visible={modalVisible}
        onCancel={toggleModal}
        users={masqueradedUsers}
      />
      <Dropdown
        trigger={['click']}
        menu={{ items }}
        placement="bottom"
        className="h-full"
      >
        <Row className="cursor-pointer">
          <Col className="flex items-center">
            {profileUUID && (
              <UserAvatar
                userUUID={profileUUID}
                userFirstName={first_name}
                userLastName={last_name}
                size={32}
              />
            )}
          </Col>
          <Col className="flex items-center ml-4">
            <h4 className="m-0" style={{ userSelect: 'none' }}>
              {getNameForActor(profileActor)}
            </h4>
          </Col>
          <Col className="flex items-center">
            <BaseIcon path={mdiMenuDown} size={1.5} />
          </Col>
        </Row>
      </Dropdown>
    </div>
  );
}

export default ProfileMenu;
