// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2023.02.16 || YYYY/MM/DD

import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mdi/react';
import { COLORS, PublicInterfaceIconsConstants54Origins } from './publicInterfaceIconsConstants54Origins';
import './InterfaceIcon54Origins.scss';
import BaseIcon from '../../../_ui/BaseIcon/BaseIcon';

function InterfaceIcon54Origins({
  iconName,
  color = 'dark',
  size,
}) {
  return (
    <BaseIcon
      className={`icon-shadow-${(color || 'gray').toLowerCase()}`}
      path={PublicInterfaceIconsConstants54Origins[iconName] || PublicInterfaceIconsConstants54Origins['mdi-cube']}
      color={COLORS[color] || COLORS.GRAY}
      size={size || 1}
    />
  );
}

export default InterfaceIcon54Origins;

InterfaceIcon54Origins.propTypes = {
  color: PropTypes.string,
  iconName: PropTypes.string,
  size: PropTypes.number,
};
