import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal, Tabs } from 'antd';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import ActorLogsTableAndFilters from './ActorLogsTableAndFilters';
import CRUDActorOnService from './CRUDActorOnService';

import { getActorsInfo } from '../../selectors';
import { dateFormat54origins, getNameForActor } from '../../../54origins/utils54origins';
import i18n from '../../../i18n';
import { capitalize } from '../../../mainUtils';
import AntButtonWithMDI from '../../../components/AntButtonWithMDI';
import useURLParams from '../../../hooks/useURLParams';

const translates = {
  tab1: ['actor on services', 'эктор на сервисе'],
  tab2: ['unsynchronized changes', 'изменения которые не были синхронизированы'],
  syncDashboard: ['sync dashboard', 'дашборд синх.'],
  syncDashboardFor: ['sync dashboard for', 'дашборд синхронизации для'],
};

const translateFromLocalDict = (key, isCapitalize = true) => {
  const translate = translates[key][i18n.language === 'en' ? 0 : 1];
  return isCapitalize ? capitalize(translate) : translate;
};

function SyncDashboardModal() {
  const { t } = useTranslation();

  const { clearSearchParams, getURLParams } = useURLParams();

  const { modal } = getURLParams();

  const actorData = useSelector(getActorsInfo);

  const [modalVisible, setModalVisible] = useState(false);

  const tabs = [
    {
      label: translateFromLocalDict('tab1'),
      key: '1',
      children: <CRUDActorOnService />,
    },
    {
      label: translateFromLocalDict('tab2'),
      key: '2',
      children: <ActorLogsTableAndFilters />,
    },
  ];

  const onCancel = () => {
    setModalVisible(false);
    clearSearchParams(['modal']);
  };

  useEffect(() => {
    if (modal === 'syncDashboard') {
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  }, [modal]);

  return (
    <Modal
      title={`${translateFromLocalDict('syncDashboardFor')} ${getNameForActor(actorData)} (Modify actor on auth: ${dateFormat54origins(actorData?.uinfo?.date_updated).dateWithTime})`}
      open={modalVisible}
      width="70%"
      destroyOnClose
      onCancel={onCancel}
      footer={(
        <AntButtonWithMDI
          type="primary"
          onClick={onCancel}
          label={t('54origins.verb.cancel', 'cancel')}
        />
      )}
    >
      <Tabs
          // onChange={() => {}}
        type="card"
        items={tabs}
        destroyInactiveTabPane
      />
    </Modal>
  );
}

export default SyncDashboardModal;
