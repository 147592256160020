import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button, Card, Col, Form, Radio, Row, Spin, Tabs,
} from 'antd';
import Icon from '@mdi/react';
import {
  mdiAccount,
  mdiCheckboxMultipleMarkedCircleOutline,
  mdiCog,
  mdiHexagonMultiple,
  mdiShieldAccount,
} from '@mdi/js';
import { useSelector } from 'react-redux';
import IStickyBox from 'react-sticky-box';

import InfoBlock from '../../components/InfoBlock/InfoBlock';
import ServiceInfoFormItems from './infoForms/ServiceInfoFormItems';
import GroupInfoFormItems from './infoForms/GroupInfoFormItems';
import UserInfoFormItems from './infoForms/UserInfoFormItems';
import ActorFormWrapper from './infoForms/ActorFormWrapper';
import SendActorBtn from './SendActorBtn';

import { capitalizeAndTranslateMsg, translateMsg } from '../../mainUtils';
import { isActorCreatedFetching } from '../selectors';
import BaseCard from '../../_ui/BaseCard/BaseCard';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';
import ActorTypeRadio from './ActorTypeRadio/ActorTypeRadio';

const rules = {
  user: {
    first_name: [{ max: 50 }, { required: true }],
    last_name: [{ max: 50 }, { required: true }],
    email: [{ type: 'email' }, { required: true }],
    password: [{ min: 4 }, { max: 50 }, { required: true }],
    password_confirmation: [
      {
        required: true,
        message: 'Please confirm your password!',
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('The two passwords that you entered do not match!'));
        },
      }),
    ],
  },
  group: {
    group_name: [{ min: 1 }, { max: 50 }, { required: true }],
    weight: [{ type: 'number' }, { required: true }],
  },
  service: {
    service_name: [{ min: 1 }, { max: 50 }, { required: true }],
    service_domain: [{ type: 'url' }, { required: true }],
    uuid: [{ pattern: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i, message: 'UUID is not valid' }],
    initial_key: [{ len: 130 }, { required: true }],
  },
};

function ActorCreate({
  switchView,
  onSuccess,
}) {
  // const { t } = useTranslation();
  const [actorForm] = Form.useForm();
  const creatingFetching = useSelector(isActorCreatedFetching);

  const [selectedActorType, setSelectedActorType] = useState('classic_user');

  const tMsg = (path, defaultValue) => capitalizeAndTranslateMsg(`auth.validation.messages.${path}`, defaultValue);

  const validateMessages = {
    required: tMsg('required', 'is required'),
    string: {
      len: tMsg('len', 'must be exactly ${len} characters'),
      min: tMsg('min', 'must be at least ${min} characters'),
      max: tMsg('max', 'cannot be longer than ${max} characters'),
    },
    types: {
      email: tMsg('email', 'is not a valid email'),
      number: tMsg('number', 'must be a number type'),
      url: tMsg('url', 'is not a valid URL'),
    },
  };

  const actorTypes = [
    {
      value: 'classic_user',
      label: translateMsg('auth.headers.classic_user', 'classic_user'),
      color: 'blue',
      iconPath: mdiAccount,
    },
    {
      value: 'user',
      label: translateMsg('auth.headers.user', 'user'),
      color: 'geekblue',
      iconPath: mdiShieldAccount,
    },
    {
      value: 'group',
      label: translateMsg('auth.headers.group', 'group'),
      color: 'red',
      iconPath: mdiHexagonMultiple,
    },
    {
      value: 'service',
      label: translateMsg('auth.headers.service', 'service'),
      color: 'orange',
      iconPath: mdiCog,
    },
  ];

  const handleChangeActorType = (e) => {
    const { value } = e.target;
    setSelectedActorType(value);
  };

  const generatePass = (newPass) => {
    actorForm.setFieldsValue({
      password: newPass,
      password_confirmation: newPass,
    });
  };

  const formProps = {
    editMode: true,
    creating: true,
    actorType: selectedActorType,
    actorForm,
    validateMessages,
    generatePassCallBack: generatePass,
    initialValues: {
      internal: true,
    },
  };

  useEffect(() => {
    actorForm.resetFields();
  }, [selectedActorType]);

  return (
    <IStickyBox offsetTop={16} offsetBottom={16}>
      <Spin spinning={creatingFetching}>
        <ActorFormWrapper
          {...formProps}
        >
          <BaseCard>
            <Row>
              <Col span={24}>
                <h4 className="header-primary">
                  {translateMsg('auth.headers.create_actor', 'creating new actor')}
                </h4>
              </Col>
            </Row>
            <hr className="mt-6 mb-0" />
            <InfoBlock
              iconPath={mdiCheckboxMultipleMarkedCircleOutline}
              title={translateMsg('auth.headers.choose_actor_type', 'choose actor type')}
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                value={selectedActorType}
                onChange={handleChangeActorType}
              >
                {actorTypes.map((item) => {
                  const active = item.value === selectedActorType;

                  return (
                    <ActorTypeRadio
                      key={`type_${item.value}`}
                      value={item.value}
                      className={`radio-item radio-item-${item.color} ${active ? 'active' : ''}`}
                    >
                      <div className="flex items-center">
                        <BaseIcon
                          path={item.iconPath}
                          size={1.2}
                          className="slow mr-2"
                          rotate={active ? 720 : 0}
                        />
                        {item.label}
                      </div>
                    </ActorTypeRadio>
                  );
                })}
              </Radio.Group>
            </InfoBlock>
            <hr className="my-6" />
            <Tabs
              activeKey={selectedActorType}
              tabBarStyle={{ display: 'none' }}
              destroyInactiveTabPane
              animated
            >
              <Tabs.TabPane key="classic_user">
                <UserInfoFormItems
                  {...formProps}
                  rules={rules.user}
                  hideElements={['oldPassword']}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="user">
                <UserInfoFormItems
                  {...formProps}
                  rules={rules.user}
                  hideElements={['oldPassword', 'passwordConfirmation']}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="group">
                <GroupInfoFormItems {...formProps} rules={rules.group} />
              </Tabs.TabPane>
              <Tabs.TabPane key="service">
                <ServiceInfoFormItems {...formProps} rules={rules.service} />
              </Tabs.TabPane>
            </Tabs>
            <hr className="my-6" />
            <Row>
              <Col className="mr-auto flex">
                <Form.Item shouldUpdate>
                  {() => (
                    <SendActorBtn
                      mode="create"
                      actorForm={actorForm}
                      actorType={selectedActorType}
                      onSubmitCallback={onSuccess}
                      disabled={!actorForm.isFieldsTouched()}
                    />
                  )}
                </Form.Item>
                <BaseButton
                  className="button-secondary-outlined ml-2"
                  onClick={switchView}
                >
                  {translateMsg('auth.buttons.cancel', 'cancel')}
                </BaseButton>
              </Col>
            </Row>
          </BaseCard>
        </ActorFormWrapper>
      </Spin>
    </IStickyBox>
  );
}

export default ActorCreate;

ActorCreate.propTypes = {
  onSuccess: PropTypes.func,
  switchView: PropTypes.func.isRequired,
};
