import React from 'react';
import PropTypes from 'prop-types';
import './BaseIcon.scss';
import Icon from '@mdi/react';

function BaseIcon({ uuid, ...props }) {
  return (
    <Icon
      uuid={uuid}
      {...props}
    />
  );
}

export default BaseIcon;

BaseIcon.propTypes = {
  uuid: PropTypes.string,
};
