import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { mdiKey } from '@mdi/js';

import ButtonWithPopConfirmAntDesign54origins from '../../../54origins/components/ButtonWithPopConfirmAntDesign54origins';

import { ApiContext } from '../../../api/ApiContextProvider';

function GenerateKeyPairForActorBtn({
  actor,
}) {
  const {
    requestUpdateActor,
    downLoadBackupFileRequest,
  } = useContext(ApiContext);

  const onClick = () => {
    const data = {
      ...actor,
      generate_new_keys_for_user: true,
    };

    requestUpdateActor(data).then((actorResponse) => {
      const { actor: actorRes, backup_filename: backupName } = actorResponse || {};
      const {
        uinfo: {
          first_name: fName,
          last_name: lName,
        } = {},
      } = actorRes || {};

      downLoadBackupFileRequest(backupName, `${fName}_${lName}`);
    });
  };

  return (
    <ButtonWithPopConfirmAntDesign54origins
      label="Generate new key pair"
      pathForMdi={mdiKey}
      // size="small"
      popConfirmTitle={(
        <>
          <div>After generating new keys or changing the user type, you will be prompted to download the user backup.</div>
          <div>Default password: 1111</div>
          <p />
          <div>Are you sure?</div>
        </>
      )}
      popConfirmPlacement="topLeft"
      onClick={onClick}
      popConfirmColor="#bae7ff"
      className="button-primary-outlined ml-auto"
    />
  );
}

export default GenerateKeyPairForActorBtn;

GenerateKeyPairForActorBtn.propTypes = {
  actor: PropTypes.object,
};
