import PropTypes from 'prop-types';
import React from 'react';
import { mdiFileCertificateOutline } from '@mdi/js';
import {
  Col, Form, Input, Row, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { capitalize } from '../../mainUtils';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import { getAllServices } from '../../actors/selectors';
import { getNameForActor } from '../../54origins/utils54origins';

function CertificateForm({
  form,
  disabled,
}) {
  const { t } = useTranslation();

  const listOfServices = useSelector(getAllServices);

  const tMsg = (path, defaultValue) => capitalize(t(`auth.validation.messages.${path}`, defaultValue));

  const rules = {
    certificate: [
      // { pattern: validationCertificate },
      { required: true },
    ],
    domains: [
      { required: true },
    ],
    service_uuid: [
      { pattern: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i },
    ],
  };

  const validateMessages = {
    pattern: {
      mismatch: (name) => tMsg('mismatch', { format: `$t(auth.validation.formats.${name})` }),
    },
    required: tMsg('required', 'is required'),
  };

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      form={form}
      validateMessages={validateMessages}
    >
      <InfoBlock
        iconPath={mdiFileCertificateOutline}
        title={capitalize(t('auth.headers.certificate_detail', 'certificate detail'))}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.service', 'service'))}
              name="service_uuid"
              rules={disabled ? null : rules.service_uuid}
            >
              <Select
                options={listOfServices.map((item) => ({
                  label: getNameForActor(item),
                  value: item?.uuid,
                }))}
              />
            </Form.Item>
            <Form.Item
              label={capitalize(t('auth.headers.domain', 'domain'))}
              name="domain"
              rules={disabled ? null : rules.domains}
            >
              <Input.TextArea
                placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.domains') }))}
                autoSize={{
                  minRows: 1,
                  maxRows: 10,
                }}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.public_key', 'public key'))}
              name="certificate"
              rules={disabled ? null : rules.certificate}
            >
              <Input.TextArea
                placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.public_key') }))}
                autoSize={{
                  minRows: 3,
                  maxRows: 24,
                }}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.service_uuid', 'service uuid'))}
              name="service_uuid"
              rules={disabled ? null : rules.service_uuid}
            >
              <Input
                placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.service_uuid') }))}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      </InfoBlock>
    </Form>
  );
}

export default CertificateForm;

CertificateForm.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
};
