import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mdi/react';
import { Tag } from 'antd';
import { COLORS, PublicInterfaceIconsConstants54Origins } from '../../../54origins/components/publicInterface54Origins/publicInterfaceIconsConstants54Origins';
import './IconSwitch.scss';
import BaseIcon from '../../../_ui/BaseIcon/BaseIcon';
import BaseTag from '../../../_ui/BaseTag/BaseTag';

function IconSwitch({
  checked,
  onClick,
  icon,
}) {
  return (
    <BaseTag
      className={`icon-switch ${checked ? 'checked' : ''}`}
      onClick={() => onClick(icon)}
    >
      <BaseIcon
        className="icon-shadow-gray"
        path={PublicInterfaceIconsConstants54Origins[icon]}
        color={COLORS.GRAY}
        size={1.8}
      />
    </BaseTag>
  );
}

export default IconSwitch;

IconSwitch.propTypes = {
  checked: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
