import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import './ActorTypeRadio.scss';

function ActorTypeRadio({ children, ...props }) {
  return (
    <Radio.Button
      {...props}
    >
      {children}
    </Radio.Button>
  );
}

export default ActorTypeRadio;

ActorTypeRadio.propTypes = {
  children: PropTypes.node,
};
