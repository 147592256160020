import React, {
  useCallback,
  useContext, useEffect, useReducer, useState,
} from 'react';
import { useSelector } from 'react-redux';
import Icon from '@mdi/react';
import {
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiCompareHorizontal,
  mdiPlusCircleOutline,
  mdiReload,
  mdiTrashCanOutline,
} from '@mdi/js';
import {
  Alert,
  Badge,
  Col, Row, Space, Spin,
} from 'antd';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ColumnsWrapperForBasicActorList from '../ColumnsWrapperForBasicActorList';
import BtnSendRequestAndShowResult from '../../../components/BtnSendRequestAndShowResult';

import { ApiContext } from '../../../api/ApiContextProvider';
import { getActorsInfo } from '../../selectors';
import { getAuthServiceInfo } from '../../../auth/selectors';
import useTimeout from '../../../hooks/useTimeout';
import AntButtonWithMDI from '../../../components/AntButtonWithMDI';
import {
  antNotification, capitalize, capitalizeAndTranslateMsg, translateMsg,
} from '../../../mainUtils';
import { getLocale } from '../../../locale/selectors';
import i18n from '../../../i18n';
import BaseIcon from '../../../_ui/BaseIcon/BaseIcon';

const translates = {
  p1: ['this dashboard provides information and the ability to perform certain actions to control the synchronization of the actor with biome services', 'Данный дашборд предоставляет информацию и возможность выполнения некоторых действий для контроля синхронизации эктора с сервисами биома'],
  p2: ['If the actor does not exist on the service, it is possible to force it to be created there', 'В случае, если эктора нет на сервисе, имеется возможность принудительно создать его там'],
  p3: ['If the actor exists on any service, the following options are available:', 'Если эктор существует на каком-либо сервисе, имеются следующие возможности:'],
  p4: ['Important: actors with type \'group\' and \'service\' are synchronized with all services by default', 'Важно: экторы с типом \'group\' и \'service\' синхронизируются со всеми сервисами по умолчанию'],
  p5: [],
  li1: ['compare the actor hash on the service with the hash on the Auth service', 'сравнить хэш эктора на сервисе с хэшем на Auth сервисе'],
  li2: ['force update of the actor on the service', 'принудительно обновить эктора на сервисе'],
  li3: ['force delete the actor on the service', 'принудительно удалить эктора на сервисе'],
  li4: ['view the date of the last successful synchronization of the actor with the service', 'просмотреть дату последней успешной синхронизации эктора с сервисом'],
  li5: ['view the count of successful synchronizations of the actor with the service', 'просмотреть кол-во успешных синхронизаций эктора с сервисом'],
};

function CRUDActorOnService() {
  dayjs.extend(utc);

  const {
    requestCreateActorOnService,
    requestUpdateActorOnService,
    requestDeleteActorOnService,
    requestGetActorExistenceOnServicesData,
    requestCompareActorHash,
  } = useContext(ApiContext);

  const { reset } = useTimeout(() => getServicesWhereActorCreatedOrNotCreated(), 1500);

  const [fetching, setFetching] = useReducer((previous) => !previous, false);

  const locale = useSelector(getLocale) || 'en';
  const actorData = useSelector(getActorsInfo);
  const authInfo = useSelector(getAuthServiceInfo);
  const authUUID = authInfo.uuid;

  const [currentTime, setCurrentTime] = useState('');
  const [servicesWhereActorExist, setServicesWhereActorExist] = useState([]);

  const {
    existence: existenceOnServices = [],
    sync_logs: syncLogs = [],
  } = servicesWhereActorExist || {};

  const {
    uuid: actorUUID,
    actor_type: actorType,
  } = actorData || {};

  const syncLogsIsNotNull = syncLogs?.length !== 0;

  const actorIsGroupOrService = actorType === 'group' || actorType === 'service';

  const translateFromLocalDict = (key, isCapitalize = true) => {
    const translate = translates[key][i18n.language === 'en' ? 0 : 1];
    return isCapitalize ? capitalize(translate) : translate;
  };

  const actorIsCreated = (uuid) => (
    existenceOnServices.includes(uuid) || actorIsGroupOrService || uuid === authUUID
  );

  const createOrUpdateActorOnService = (serviceUUID) => (actorIsCreated(serviceUUID)
    ? requestUpdateActorOnService(actorUUID, serviceUUID)
    : requestCreateActorOnService(actorUUID, serviceUUID));

  const getServicesWhereActorCreatedOrNotCreated = useCallback(async () => {
    await setFetching();

    requestGetActorExistenceOnServicesData(actorUUID).then((res) => {
      setServicesWhereActorExist(res);
      setCurrentTime(new Date().getTime());
    }).catch(() => {

    }).finally(() => {
      setFetching();
    });
  }, [actorUUID]);

  const actionCreateActorOnService = [
    {
      dataIndex: 'uuid',
      key: 'lastModify',
      className: 'p-2',
      title: capitalizeAndTranslateMsg('54origins.noun.modify', 'last modify'),
      render: (cell) => (
        cell && syncLogsIsNotNull && syncLogs[cell] && capitalize(dayjs.utc(syncLogs[cell]?.last_synchronized_at)
          .local()
          .locale(locale)
          .format('MMM D, h:mm A'))
      ),
    },
    {
      dataIndex: 'uuid',
      key: 'totalUpdated',
      className: 'p-2',
      title: capitalizeAndTranslateMsg('54origins.noun.totals', 'total updates'),
      render: (cell) => (
        cell && syncLogsIsNotNull && syncLogs[cell] && (
          <Badge
            count={syncLogs[cell]?.total_sync_times}
            color="blue"
          />
        )
      ),
    },
    {
      dataIndex: 'uuid',
      key: 'actorExist',
      className: 'p-2',
      title: capitalizeAndTranslateMsg('54origins.noun.exists', 'exists'),
      render: (cell) => (
        <BaseIcon
          size={2}
          path={actorIsCreated(cell) ? mdiCheckCircleOutline : mdiCloseCircleOutline}
          color={actorIsCreated(cell) ? 'green' : 'red'}
        />
      ),
    },
    {
      dataIndex: 'uuid',
      key: 'actions',
      className: 'p-2',
      title: capitalizeAndTranslateMsg('54origins.noun.action', 'action'),
      width: 100,
      render: (cell) => (
        <Row>
          <Col span={24}>
            <Space>
              {actorIsCreated(cell) && (
                <BtnSendRequestAndShowResult
                  action={() => requestCompareActorHash({
                    serviceUUID: cell,
                    actorUUID,
                  })}
                  successActionCallback={(res) => (
                    res?.hash_matches
                      ? antNotification.success('Actor hash matches the hash on the service')
                      : antNotification.warning('Actor hash does not match the hash on the service'))}
                  btnLabel="Compare actor hash"
                  disabledBtn={cell === authUUID}
                    // finallyActionCallback={reset}
                  iconBeforeLabel
                  iconSize={1.2}
                  pathForIcon={mdiCompareHorizontal}
                  tooltipPlacement="left"
                  requestWithoutAnyFurtherAction
                />
              )}
              <BtnSendRequestAndShowResult
                action={() => createOrUpdateActorOnService(cell)}
                btnLabel={`
                ${capitalizeAndTranslateMsg(`54origins.verb.${actorIsCreated(cell) ? 'update' : 'create'}`)} 
                ${translateMsg('54origins.noun.service.2')}`}
                disabledBtn={cell === authUUID}
                finallyActionCallback={reset}
                iconBeforeLabel
                iconSize={1.2}
                pathForIcon={actorIsCreated(cell) ? mdiReload : mdiPlusCircleOutline}
                tooltipPlacement="left"
              />
              {actorIsCreated(cell) && !actorIsGroupOrService && (
              <BtnSendRequestAndShowResult
                action={() => requestDeleteActorOnService(actorUUID, cell)}
                btnLabel={capitalizeAndTranslateMsg('54origins.verb.delete')}
                btnType="danger"
                disabledBtn={cell === authUUID}
                finallyActionCallback={reset}
                iconBeforeLabel
                iconSize={1.2}
                pathForIcon={mdiTrashCanOutline}
                popConfirmTitle={(
                  <div>
                    <div>Attention! After successful deletion of the actor,</div>
                    <div>all his personal perms for this service will be deleted,</div>
                    <div>are you sure?</div>
                  </div>
                      )}
                tooltipPlacement="right"
                withPopConfirm
              />
              )}
            </Space>
          </Col>
        </Row>
      ),
    }];

  useEffect(() => {
    if (actorUUID) {
      getServicesWhereActorCreatedOrNotCreated();
    }
  }, [actorUUID]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Alert
            type="info"
            showIcon
            description={(
              <>
                <p>
                  {translateFromLocalDict('p1')}
                </p>
                <p>
                  {translateFromLocalDict('p2')}
                </p>
                <p>
                  {translateFromLocalDict('p3')}
                  <ul>
                    <li>{translateFromLocalDict('li1')}</li>
                    <li>{translateFromLocalDict('li2')}</li>
                    <li>{translateFromLocalDict('li3')}</li>
                    <li>{translateFromLocalDict('li4')}</li>
                    <li>{translateFromLocalDict('li5')}</li>
                  </ul>
                </p>
                <p>
                  {translateFromLocalDict('p4')}
                </p>
              </>
              )}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <AntButtonWithMDI
            onClick={getServicesWhereActorCreatedOrNotCreated}
            label={capitalizeAndTranslateMsg('54origins.verb.update_2', 'update on all services')}
            disabled={fetching}
            pathForMdi={mdiReload}
            sizeMdi={1.2}
            iconClassName="mb-1 mr-1"
            type="primary"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Spin spinning={fetching}>
            <ColumnsWrapperForBasicActorList
              getActorTypes={['service']}
              typeOfColumns="servicesSessions"
              additionalColumns={actionCreateActorOnService}
              getListOfActorsAgain={currentTime}
            />
          </Spin>
        </Col>
      </Row>
    </>
  );
}

export default CRUDActorOnService;

// CreateActorOnServiceBtn.propTypes = {
//   service: PropTypes.string,
// };
