import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import IStickyBox from 'react-sticky-box';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Icon from '@mdi/react';
import { mdiNewspaperVariantOutline, mdiViewDashboardVariantOutline } from '@mdi/js';
import PermissionsManagement from './PermissionsManagement';
import ActorInfo from '../../actors/components/infoForms/ActorInfo/ActorInfo';
import PermissionInfo from './PermissionInfo';
import TagsSettingList from '../../components/TagsSettingList/TagsSettingList';

import {
  getActorPermissions,
  getActorPermissionsMap,
  getDefaultPermissions,
  getGroupPermissions,
  getGroupPermissionsMap,
  getUnions,
} from '../selectors';
import {
  getActorsInfo,
  getAllServices,
  getDefaultAdminGroupUUID,
  getDefaultBanGroupUUID,
  isInfoFetching,
} from '../../actors/selectors';
import { ApiContext } from '../../api/ApiContextProvider';
import { antNotification, capitalize } from '../../mainUtils';
import useURLParams from '../../hooks/useURLParams';
import PermissionDigest from './PermissionDigest';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';

function PermissionsPage({
  onSuccessUpdate,
}) {
  const { t } = useTranslation();

  const {
    requestSetPermissions,
    requestDeletePermissions,
    requestGetDefaultPermissions,
    requestGetGroupPermissions,
    requestGetActorPermissions,
    requestServiceSpecificAdmin,
    requestGetAllServices,
  } = useContext(ApiContext);

  const { setSearchParams, getURLParams, routeNavigateAndClearParams } = useURLParams();

  const {
    service: selectedService,
    tab,
  } = getURLParams();

  const actorData = useSelector(getActorsInfo);
  const groupPermissions = useSelector(getGroupPermissions);
  const actorPermissions = useSelector(getActorPermissions);
  const defaultPermissions = useSelector(getDefaultPermissions);
  const actorPermissionsMap = useSelector(getActorPermissionsMap);
  const groupPermissionsMap = useSelector(getGroupPermissionsMap);
  const allServices = useSelector(getAllServices);
  const isActorFetching = useSelector(isInfoFetching);
  const allUnions = useSelector(getUnions);
  const banGroupUUID = useSelector(getDefaultBanGroupUUID);
  const adminGroupUUID = useSelector(getDefaultAdminGroupUUID);

  const [selectedPermission, setSelectedPermission] = useState(null);
  const [permissionTableType, setPermissionTableType] = useState('');
  const [permissionParams, setPermissionParams] = useState('');
  const [updatedPermissions, setUpdatedPermissions] = useState([]);
  const [permissionParamsError, setPermissionParamsError] = useState(false);
  const [deletedPermissions, setDeletedPermissions] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [hiddenPermSections, setHiddenPermSections] = useState([]);
  const [serviceIsAdminFlag, setServiceIsAdminFlag] = useState(false);

  const [activeTab, setActiveTab] = useState('permDashboard');

  const initServiceUUID = selectedService || _.get(allServices, '[0].uuid', '');

  const [serviceUUID, setServiceUUID] = useState(initServiceUUID);

  const [dataTable, setDataTable] = useState({
    default: new Map(),
    actor: new Map(),
    group: new Map(),
  });

  const getPermUUID = (perm) => _.get(perm, 'permaction_uuid');

  const {
    uuid: actorUUID,
    actor_type: actorType,
    root_perms_signature: rootPermsSignature = false,
    uinfo: {
      groups: actorGroups = [],
    } = {},
  } = actorData || {};

  const showNotifications = (num) => {
    switch (num) {
      case 1:
        antNotification.success(
          capitalize(t('auth.notifications.perms_updated', 'permissions was updated')),
        );
        break;
      case 2:
        antNotification.error(
          capitalize(t('auth.notifications.perms_update_failed', 'permissions update failed')),
        );
        break;
      case 3:
        antNotification.success(
          capitalize(t('auth.notifications.perms_deleted', 'permissions was deleted')),
        );
        break;
      case 4:
        antNotification.error(
          capitalize(t('auth.notifications.perms_delete_failed', 'permissions delete failed')),
        );
        break;
      default:
        break;
    }
  };

  const onSuccessDelete = () => {
    routeNavigateAndClearParams('/');
  };

  const handleSelectedFilters = (filters = []) => {
    const service = _.get(_.find(filters, ({ key }) => key === 'service'), 'value');
    const attributes = filters.filter(({ key }) => key === 'union').map(({ value }) => value);

    setServiceUUID(service);
    setAttributes(attributes);

    setSearchParams({
      service,
    });
  };

  const onSelectPermission = (permission) => {
    const params = JSON.stringify(_.get(permission, 'params', ''), null, 4);
    setPermissionParams(params);
    setSelectedPermission(permission);
  };

  const checkUpdatedPermissions = (uuid, data) => {
    const currentMap = actorType === 'group' ? groupPermissionsMap : actorPermissionsMap;

    if (!currentMap.has(uuid)) {
      setUpdatedPermissions((prev) => [...prev.filter((item) => item !== uuid), uuid]);
    } else {
      const defaultData = currentMap.get(uuid);

      if (defaultData.value !== data.value || JSON.stringify(defaultData.params) !== JSON.stringify(data.params)) {
        setUpdatedPermissions((prev) => [...prev.filter((item) => item !== uuid), uuid]);
      } else {
        setUpdatedPermissions((prev) => prev.filter((item) => item !== uuid));
      }
    }
  };

  const buildDataTable = (type, permissions) => {
    setDataTable((prev) => (
      {
        ...prev,
        [type]: new Map([
          ...permissions.map((item) => [getPermUUID(item), item]),
        ]),
      }
    ));
  };

  const resetPermissions = () => {
    if (actorType !== 'group') {
      buildDataTable('actor', actorPermissions);
    } else {
      buildDataTable('actor', []);
    }

    buildDataTable('group', groupPermissions);

    buildDataTable('default', defaultPermissions);

    setUpdatedPermissions([]);
    setDeletedPermissions([]);
    onSelectPermission(null);
    setPermissionTableType('');
  };

  const onUpdatePermissions = async () => {
    const updateData = updatedPermissions.map((uuid) => {
      const permissionData = actorType === 'group' ? dataTable.group.get(uuid) : dataTable.actor.get(uuid);
      const { value, params } = permissionData;

      return {
        permaction_uuid: uuid,
        actor_uuid: actorUUID,
        service_uuid: serviceUUID,
        value,
        params,
      };
    });

    const deleteData = deletedPermissions.map((uuid) => (
      {
        permaction_uuid: uuid,
        actor_uuid: actorUUID,
        service_uuid: serviceUUID,
      }
    ));

    if (updateData.length > 0) {
      await requestSetPermissions(updateData, actorType === 'group')
        .then(() => showNotifications(1))
        .catch(() => showNotifications(2));
    }

    if (deleteData.length > 0) {
      await requestDeletePermissions(deleteData, actorType === 'group')
        .then(() => showNotifications(3))
        .catch(() => showNotifications(4));
    }

    initFunc();
  };

  const editPermissionType = actorType === 'group' ? 'group' : 'actor';

  const onChangePermissionParams = (value) => {
    setPermissionParams(value);
    const uuid = _.get(selectedPermission, 'permaction_uuid');

    try {
      const params = JSON.parse(value);
      const data = {
        ...selectedPermission,
        params,
      };

      setDataTable((prev) => {
        prev[editPermissionType].set(uuid, data);

        return {
          ...prev,
        };
      });

      checkUpdatedPermissions(uuid, data);
      setPermissionParamsError(false);
    } catch {
      setUpdatedPermissions((prev) => prev.filter((item) => item !== uuid));
      setPermissionParamsError(true);
    }
  };

  const permissionEditable = actorType === 'group'
    ? permissionTableType === 'group'
    : permissionTableType === 'actor';

  const getSpecialPermissions = () => {
    if (rootPermsSignature) {
      return 'root';
    }

    if (actorGroups.includes(banGroupUUID)) {
      return 'ban';
    }

    if (actorGroups.includes(adminGroupUUID)) {
      return 'admin';
    }
  };

  const filterTags = [
    {
      tags: [
        ...allServices.map((service) => (
          {
            key: 'service',
            value: service.uuid,
            label: _.get(service, 'uinfo.service_name'),
          }
        )),
      ],
      isRadio: true,
      noDeselect: true,
      isRow: true,
      rowText: capitalize(t('auth.headers.services', 'services')),
    },
    {
      tags: [
        ...allUnions.map((union) => (
          {
            key: 'union',
            value: union,
            label: union,
          }
        )),
      ],
      isRow: true,
      rowText: capitalize(t('auth.headers.attributes', 'attributes')),
      className: 'filter-red',
    },
  ];

  const permissionsFilter = (
    <TagsSettingList
      defaultSelectedTags={[
        {
          key: 'service',
          value: selectedService || serviceUUID,
        },
      ]}
      allTags={filterTags}
      onSelectTags={handleSelectedFilters}
      id="allActors"
      small
    />
  );

  const userWithSpecialPermissions = getSpecialPermissions();
  const hasUnsavedData = updatedPermissions.length !== 0 || deletedPermissions.length !== 0;

  const handleSelectPermission = (permission, type) => {
    if (getPermUUID(permission) === getPermUUID(selectedPermission) && permissionTableType === type) {
      onSelectPermission(null);
      setPermissionTableType('');
    } else {
      onSelectPermission(permission);
      setPermissionTableType(type);
    }
  };

  const getPermissionsForNotAdmins = () => {
    const groups = actorType === 'group' ? [actorUUID] : _.get(actorData, 'uinfo.groups', []);

    if (actorType !== 'group') {
      requestGetActorPermissions(serviceUUID, actorUUID)
        .then((permissions) => buildDataTable('actor', permissions));
    } else {
      buildDataTable('actor', []);
    }

    requestGetGroupPermissions(serviceUUID, groups)
      .then((permissions) => buildDataTable('group', permissions));

    requestGetDefaultPermissions(serviceUUID, actorUUID)
      .then((permissions) => buildDataTable('default', permissions));
  };

  const sendServiceSpecificAdminRequest = (action) => requestServiceSpecificAdmin({
    action: action || 'get',
    service_uuid: serviceUUID,
    actor_uuid: actorUUID,
  });

  const checkServiceIsAdmin = () => {
    // console.log('checkServiceIsAdmin')
    sendServiceSpecificAdminRequest('get').then((res) => {
      const { is_service_admin, disable_for_service } = res || {};

      setServiceIsAdminFlag(is_service_admin);
      // console.log('checkServiceIsAdmin is_service_admin', is_service_admin)
      if (is_service_admin) {
        setHiddenPermSections(['actor', 'group', 'default']);
      } else {
        setHiddenPermSections(disable_for_service ? ['admin'] : []);
        getPermissionsForNotAdmins();
      }
    });
  };

  const changeServiceAdmin = () => {
    const action = serviceIsAdminFlag ? 'remove' : 'set';

    sendServiceSpecificAdminRequest(action).then((res) => {
      const { message } = res || {};

      antNotification.success(message);
      checkServiceIsAdmin();
    })
      .catch(() => {

      });
  };

  const onChangeTab = (activeKey) => {
    setActiveTab(activeKey);
    setSearchParams({ tab: activeKey });
  };

  const initFunc = () => {
    onSelectPermission(null);
    setPermissionTableType('');
    setDeletedPermissions([]);
    setUpdatedPermissions([]);
    setAttributes([]);

    checkServiceIsAdmin();
  };

  useEffect(() => {
    if (actorUUID && serviceUUID && !userWithSpecialPermissions && !isActorFetching) {
      initFunc();
    }
  }, [actorUUID, serviceUUID, isActorFetching]);

  useEffect(() => {
    requestGetAllServices().then((res) => {
      if (!selectedService) {
        setServiceUUID(_.get(res, 'actors.[0].uuid', ''));
      }
    });
  }, []);

  useEffect(() => {
    if (activeTab !== tab) {
      onChangeTab(tab);
    }
  }, [tab]);

  useEffect(() => {
    if (selectedService !== serviceUUID) {
      setServiceUUID(selectedService);
    }
  }, [selectedService]);

  const items = [
    {
      label: (
        <>
          <BaseIcon className="mr-1 mb-1" size={1.2} path={mdiViewDashboardVariantOutline} />
          <span>Dashboard</span>
        </>
      ),
      key: 'permDashboard',
      children: <>
        {allServices.length > 0 && actorUUID ? (
          <PermissionsManagement
            actorType={actorType}
            attributes={attributes}
            checkUpdatedPermissions={checkUpdatedPermissions}
            dataTable={dataTable}
            handleSelectPermission={handleSelectPermission}
            hasUnsavedData={hasUnsavedData}
            hideElements={hiddenPermSections}
            onUpdatePermissions={onUpdatePermissions}
            permissionsFilter={permissionsFilter}
            resetPermissions={resetPermissions}
            selectedPermission={selectedPermission}
            selectedPermissionTableType={permissionTableType}
            changeServiceAdmin={changeServiceAdmin}
            setDataTable={setDataTable}
            setDeletedPermissions={setDeletedPermissions}
            setPermissionTableType={setPermissionTableType}
            setUpdatedPermissions={setUpdatedPermissions}
            userWithSpecialPermissions={userWithSpecialPermissions}
          />
        ) : <p>Service list not found.</p>}
      </>,
    },
    {
      label: (
        <>
          <BaseIcon className="mr-1 mb-1" size={1.2} path={mdiNewspaperVariantOutline} />
          <span>Digest</span>
        </>
      ),
      key: 'permDigest',
      children: <PermissionDigest />,
    },
  ];

  return (
    <Row gutter={[24, 16]}>
      <Col span={14}>
        <Tabs
          activeKey={activeTab}
          items={items}
          type="card"
          onChange={onChangeTab}
        />
      </Col>
      <Col span={10}>
        <IStickyBox offsetTop={16} offsetBottom={16}>
          <Row gutter={[24, 16]}>
            <Col span={24}>
              <ActorInfo
                onSuccessUpdate={onSuccessUpdate}
                onSuccessDelete={onSuccessDelete}
                hideElements={['permsBtn', 'applyFilterBtn']}
                isCollapsed
              />
            </Col>
            {selectedPermission && (
              <Col span={24}>
                <PermissionInfo
                  permission={selectedPermission}
                  editEnabled={permissionEditable}
                  params={permissionParams}
                  onChangeParams={onChangePermissionParams}
                  paramsError={permissionParamsError}
                  resetPermissions={resetPermissions}
                  onUpdatePermissions={onUpdatePermissions}
                  hasUnsavedData={hasUnsavedData}
                  permissionTableType={permissionTableType}
                />
              </Col>
            )}
          </Row>
        </IStickyBox>
      </Col>
    </Row>
  );
}

export default PermissionsPage;

PermissionsPage.propTypes = {
  onSuccessUpdate: PropTypes.func,
};
