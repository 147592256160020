import React, { useContext, useReducer } from 'react';
import {
  Button, Col, Row, Tooltip, Popconfirm,
} from 'antd';
import { useTranslation } from 'react-i18next';

import ColumnsWrapperForBasicActorList from '../ColumnsWrapperForBasicActorList';

import { ApiContext } from '../../../api/ApiContextProvider';
import { antNotification, capitalize } from '../../../mainUtils';
import BaseButton from '../../../_ui/BaseButton/BaseButton';

function ResetServiceSessionBtn({
  title,
  action,
  serviceData,
  tooltipTitle = '',
  titlePathForTranslate = '',
  tooltipPathForTranslate = '',
}) {
  const { t } = useTranslation();
  const { requestResetSessionsForService, checkServiceSessions } = useContext(ApiContext);

  const {
    uuid: serviceUUID,
    uinfo: {
      service_name = '',
    } = {},
  } = serviceData || {};

  const [fetching, setFetching] = useReducer((state) => !state, false);
  const [confirmFlag, setConfirmFlag] = useReducer((state) => !state, false);

  const restSessionForService = () => {
    setFetching();
    requestResetSessionsForService({ serviceUUID, action }).then((res) => {
      antNotification.success('Success', `Service ${service_name}`);
      setFetching();
      setConfirmFlag();
    }).catch(() => {
      setFetching();
      setConfirmFlag();
    });
  };

  const checkSessionForService = () => {
    setFetching();
    checkServiceSessions(serviceUUID).then(() => {
      antNotification.success('Success', `Service ${service_name}`);
      setFetching();
      setConfirmFlag();
    }).catch(() => {
      setFetching();
      setConfirmFlag();
    });
  };

  const getFuncForBtn = () => {
    switch (action) {
      case 'check_session':
        checkSessionForService();
        break;
      default:
        return restSessionForService();
    }
  };

  return (
    <Popconfirm
      open={confirmFlag}
      title="Are you sure?"
      onConfirm={getFuncForBtn}
      onCancel={setConfirmFlag}
      okText="Yes"
      cancelText="No"
    >
      <BaseButton
        className="ml-4 button-primary-outlined"
        size="small"
        disabled={fetching}
        onClick={setConfirmFlag}
      >
        <Tooltip
          placement="bottomLeft"
          title={capitalize(t(tooltipPathForTranslate, tooltipTitle))}
          color="geekblue"
        >
          {fetching ? 'Pending...' : capitalize(t(titlePathForTranslate, title))}
        </Tooltip>
      </BaseButton>
    </Popconfirm>
  );
}

function ServicesSessions() {
  const actionFormatter = (cell, row) => (

    <Row justify="space-between">
      <Col span={24} className="flex">
        <ResetServiceSessionBtn
          serviceData={row}
          action="check_session"
          title="Check service communication"
          titlePathForTranslate="auth.messages.check_service_communication_label"
          tooltipPathForTranslate="auth.messages.service_communication_info"
        />
        <ResetServiceSessionBtn
          serviceData={row}
          action="for_service_as_actor"
          title="Reset sessions for service as actor"
          titlePathForTranslate="auth.messages.reset_sessions_as_actor_label"
          tooltipPathForTranslate="auth.messages.reset_sessions_for_service_as_actor_info"
        />
        <ResetServiceSessionBtn
          serviceData={row}
          action="for_users"
          title="Reset user sessions"
          titlePathForTranslate="auth.messages.reset_user_sessions"
          tooltipPathForTranslate="auth.messages.reset_sessions_for_users_info"
        />
        <ResetServiceSessionBtn
          serviceData={row}
          action="full_reset"
          title="Full sessions reset"
          titlePathForTranslate="auth.messages.full_sessions_reset"
          tooltipPathForTranslate="auth.messages.full_sessions_reset_info"
        />
      </Col>
    </Row>
  );

  const actionSessions = [{
    dataIndex: 'uuid',
    key: 'actions',
    className: 'flex p-2 pr-4',
    title: '',
    render: actionFormatter,
  }];

  return (
    <>
      {/* {selectedActors.size !== 0 && ( */}
      {/*    <Row className={'mb-2'}> */}
      {/*        <Col> */}
      {/*            <BaseButton */}
      {/*                type={'primary'} */}
      {/*                size={'small'} */}
      {/*                onClick={checkKeyPairs} */}
      {/*            > */}
      {/*                Check key pairs */}
      {/*            </BaseButton> */}
      {/*        </Col> */}
      {/*    </Row> */}
      {/* )} */}
      <Row>
        <Col span={24}>
          <ColumnsWrapperForBasicActorList
            getActorTypes={['service']}
            typeOfColumns="servicesSessions"
                        // withCheckBox
            additionalColumns={actionSessions}
          />
        </Col>
      </Row>
    </>
  );
}

export default ServicesSessions;
