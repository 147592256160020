import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Tag } from 'antd';
import BaseTag from '../../_ui/BaseTag/BaseTag';

function InternalOrExternalTag({
  internal,
  className,
}) {
  return (
    <BaseTag
      className={className}
      color={internal ? '#108ee9' : '#fa8c16'}
    >
      {internal ? 'Internal' : 'External'}
    </BaseTag>
  );
}

export default memo(InternalOrExternalTag);

InternalOrExternalTag.propTypes = {
  className: PropTypes.string,
  internal: PropTypes.bool,
};
