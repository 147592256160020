import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card, Col, Empty, Result, Row,
} from 'antd';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';

import AntTable from '../../components/AntTable';

import { ApiContext } from '../../api/ApiContextProvider';
import useURLParams from '../../hooks/useURLParams';
import BaseCard from '../../_ui/BaseCard/BaseCard';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';

function PermissionDigest({ isModal }) {
  const { requestGetPermissionsDigest } = useContext(ApiContext);
  const { getURLParams, setSearchParams, routeNavigateAndClearParams } = useURLParams();

  const {
    uuid: actorUUID,
  } = getURLParams();

  const [perms, setPerms] = useState([]);

  const columns = [{
    title: 'Allowed permissions',
    key: 'name',
  }];

  useEffect(() => {
    requestGetPermissionsDigest(actorUUID).then((res) => {
      const transformArr = Object.keys(res).map((key) => ({
        service_name: key,
        ...res[key],
      }));

      setPerms(transformArr.sort());
    });
  }, []);

  return (
    <Row gutter={[24, 16]}>
      {perms.map((item) => (
        <Col span={24}>
          <BaseCard title={(
            <Row>
              <Col span={12}>
                <span>{item?.service_name}</span>
              </Col>
              <Col span={12} className="flex">
                <BaseButton
                  type="link"
                  className="ml-auto"
                  onClick={() => (isModal ? routeNavigateAndClearParams('/admin/actors/permissions', {
                    tab: 'permDashboard',
                    service: item?.service_uuid,
                  }) : setSearchParams({
                    tab: 'permDashboard',
                    service: item?.service_uuid,
                  }))}
                >
                  go to service
                  <BaseIcon path={mdiChevronRight} size={1.4} />
                </BaseButton>
              </Col>
            </Row>
          )}
          >
            {item?.is_admin_for_service ? (
              <Result
                title="Actor is admin"
              />
            ) : (
              <AntTable
                columns={columns}
                data={item?.allowed_perms}
                tableSize="small"
                disablePagination
              />
            )}
          </BaseCard>
        </Col>
      ))}
      {perms.length === 0 ? (
        <Col span={24}>
          <BaseCard>
            <Empty description="Permissions not found" />
          </BaseCard>
        </Col>
      ) : null}
    </Row>
  );
}

export default PermissionDigest;
