import React, {
  useContext, useEffect, useReducer, useState,
} from 'react';
import {
  Alert, Button, Modal, Col, Row, Tag, Tooltip, Dropdown,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiAutorenew, mdiCheck, mdiClose, mdiHelpCircleOutline, mdiRefresh,
} from '@mdi/js';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { antNotification, capitalize } from '../../mainUtils';

import AntTable from '../../components/AntTable';
import HelpIconWithHover from '../../components/HelpIconWithHover';

import { ApiContext } from '../../api/ApiContextProvider';
import {
  getSyncServicesData,
  getSyncServicesFetching,
} from '../../permissions/selectors';
import { getAllServicesMap } from '../../actors/selectors';
import { getAuthServiceInfo } from '../../auth/selectors';
import { getMeIsRoot, getMeIsRootOrAdmin } from '../../profile/selectors';
import { dateFormat54origins } from '../../54origins/utils54origins';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';
import BaseTag from '../../_ui/BaseTag/BaseTag';

const translateSyncServicesPage = {
  fullDescription: [
    'Approximate execution timeframes depending on the number of actors in the biome: 20000 - 3-6s 50000 - 6-13s 100000 - 15-25s etc.',
    'Примерные временные рамки выполнения в зависимости от количества экторов в биоме:\n'
    + '20000 - 3-6с\n'
    + '50000 - 6-13с\n'
    + '100000 - 15-25с\n'
    + 'и тд'],
  question: ['Are you sure to force sync this service?', 'Вы уверены, что хотите запустить принудительную синхронизацию?'],
  description: ['The \'Force Synchronization\' process involves sending special packages to fully synchronize the data of actors and permissions in accordance with the data of the Auth service.\n'
  + 'There are various modes available, each of which will sync the data corresponding to its name.\n'
  + 'It is important to be sure that you need to run full synchronization (includes user synchronization), which can take some time and carries greater risks than other modes.\n'
  + 'These actions are available only for the Root user.',

  'Процесс \'Принудительная синхронизация\' подразумевает отправку специальных пакетов для полной синхронизации данных экторов и прав доступа в соответствии с данными Auth-сервиса.\n'
  + 'Доступны различные режимы, в каждом из которых будут синхронизированы соответствующие его названию данные.\n'
  + 'Важно быть уверенным в необходимости запуска полной синхронизации(включает синхронизацию юзеров), который может занять некоторое время и несет в себе бОльшие риски, чем остальные режимы.\n'
  + 'Данные действия доступны только для Root пользователя.'],
  groups_and_services_only: ['only for groups and services', 'только для групп и сервисов'],
  groups_only: ['only for groups', 'только для групп'],
  services_only: ['only for services', 'только для сервисов'],
  permissions_only: ['only for permissions', 'только для пермишинов'],
  full: [' ', ' '],
  last_sync: ['Date of last successful force synchronization', 'Дата последней успешной принудительной синхронизации'],
};

function SyncServicesPage() {
  const {
    requestGetSyncServicesInfo,
    requestGetAllServices,
    requestForceSyncService,
  } = useContext(ApiContext);

  const { i18n: { language } } = useTranslation();
  const { t } = useTranslation();

  const meIsRoot = useSelector(getMeIsRoot);
  const meIsRootOrAdmin = useSelector(getMeIsRootOrAdmin);
  const { uuid: authUUID } = useSelector(getAuthServiceInfo);
  const allServicesMap = useSelector(getAllServicesMap);
  const syncServicesData = useSelector(getSyncServicesData);
  const fetching = useSelector(getSyncServicesFetching);

  const [permActionsHashData, setPermActionsHashData] = useState(null);
  const [celeryActive, setCeleryActive] = useState(true);
  const [forceSyncService, setForceSyncService] = useState('');
  const [forceSyncMode, setForceSyncMode] = useState('full');

  const [isModalOpen, setModal] = useReducer((state) => !state, false);

  const checkLangAndGetTranslate = (key, isCapitalize = true) => {
    const translate = translateSyncServicesPage[key][language === 'en' ? 0 : 1];
    return isCapitalize ? capitalize(translate) : translate;
  };

  const initFunc = () => {
    if (allServicesMap.size === 0) {
      requestGetAllServices();
    } else {
      requestGetSyncServicesInfo()
        .then((response) => {
          const permData = _.get(response, `sync_data.${authUUID}.permactions_hash_data`);

          setPermActionsHashData(permData);
          setCeleryActive(_.get(response, 'celery_active', false));
        })
        .catch(() => {
          antNotification.error(capitalize(t('auth.notifications.response_error', 'data retrieval error')));
        });
    }
  };

  const saveCurrentForceSyncServiceAndOpenModal = (cell, mode) => {
    setForceSyncService(cell);
    setForceSyncMode(mode);

    setModal();
  };

  const forceSync = () => {
    requestForceSyncService(forceSyncService, forceSyncMode)
      .then(({ message }) => {
        antNotification.success(message);
        initFunc();
      });

    setModal();
  };

  const formatHash = (hash) => (
    <Tooltip title={hash}>
      {hash}
    </Tooltip>
  );

  const formatAvailable = (cell) => (
    <div className="flex justify-center">
      <Tooltip title={capitalize(
        cell
          ? t('auth.labels.service_active', 'service active')
          : t('auth.labels.service_inactive', 'service inactive'),
      )}
      >
        <BaseTag
          className={`tag-icon tag-${cell ? 'green' : 'red'}`}
        >
          <BaseIcon path={cell ? mdiCheck : mdiClose} size={1.2} />
        </BaseTag>
      </Tooltip>
    </div>
  );

  const formatHardSync = (cell, row) => {
    if (row.uuid === authUUID) {
      return null;
    }

    const items = [
      {
        label: 'groups and services only',
        key: 0,
        value: 'groups_and_services_only',
        // icon: <UserOutlined />,
      },
      {
        label: 'groups only',
        key: 1,
        value: 'groups_only',
        // icon: <UserOutlined />,
      },
      {
        label: 'services only',
        key: 2,
        value: 'services_only',
        // icon: <UserOutlined />,
      },
      {
        label: 'permissions only',
        key: 3,
        value: 'permissions_only',
        // icon: <UserOutlined />,
      },
    ];

    const handleButtonClick = () => {
      if (meIsRoot) {
        saveCurrentForceSyncServiceAndOpenModal(cell, 'full');
      }
    };
    const handleMenuClick = (e) => {
      if (meIsRootOrAdmin) {
        saveCurrentForceSyncServiceAndOpenModal(cell, items[e?.key]?.value);
      }
    };

    return (
      <div className="w-full">
        <Dropdown.Button
          size="small"
          disabled={!row.is_available}
          menu={{
            items,
            onClick: handleMenuClick,
          }}
          onClick={handleButtonClick}
        >
          <Tooltip
            title={!meIsRoot ? 'Only for root user' : ''}
            placement="left"
          >
            <BaseIcon path={mdiAutorenew} size={1} className="mr-1" />
            {capitalize(t('auth.buttons.force_sync', 'force sync'))}
          </Tooltip>
        </Dropdown.Button>
      </div>
    );
  };

  const formatActorsHash = (cell, row, type) => {
    const authService = syncServicesData[authUUID];
    const hashesIsEqual = authService[type === 'groups' ? 'groups_hash' : 'services_hash'] === cell;
    const hashColor = row.is_auth_service ? null : { color: hashesIsEqual ? 'green' : 'red' };

    return (
      <span
        style={hashColor}
      >
        {cell}
      </span>
    );
  };

  const formatPermsHash = (cell, row, type) => {
    if (row.uuid === authUUID || !cell) {
      return null;
    }

    const authPermActionsHash = _.get(permActionsHashData, `${row.uuid}.${type}_permactions_hash`);
    const servicePermActionsHash = _.get(cell, `${type}_permactions_hash`);
    const hashesIsEqual = (authPermActionsHash === servicePermActionsHash);

    return (
      <span style={{ color: hashesIsEqual ? 'green' : 'red' }}>
        Auth:
        {' '}
        {formatHash(authPermActionsHash)}
        <br />
        Service:
        {' '}
        {formatHash(servicePermActionsHash)}
      </span>
    );
  };

  const columns = [
    {
      dataIndex: 'is_available',
      key: 'is_available',
      // title: capitalize(
      //   t('auth.headers.service_available', 'service available')),
      className: 'p-2',
      width: 38,
      render: formatAvailable,
    },
    {
      dataIndex: 'service_name',
      key: 'service_name',
      title: capitalize(t('auth.headers.service_name', 'service name')),
      textWrap: 'word-break',
      className: 'p-2 pl-3',
      // render: formatService,
    },
    {
      title: () => (
        <>
          <HelpIconWithHover
            iconClassName="mr-2"
            toolTipText={t('auth.messages.sync_sections_actor_info')}
          />
          {capitalize(t('auth.headers.actors', 'actors'))}
        </>
      ),
      className: 'p-2',
      children: [
        {
          dataIndex: 'groups_hash',
          key: 'groups_hash',
          title: () => capitalize(t('auth.headers.groups', 'groups')),
          // textWrap: 'word-break',
          className: 'p-2',
          ellipsis: true,
          render: (cell, row) => formatActorsHash(cell, row, 'groups'),
        },
        {
          dataIndex: 'services_hash',
          key: 'services_hash',
          title: () => capitalize(t('auth.headers.services', 'services')),
          // textWrap: 'word-break',
          className: 'p-2',
          ellipsis: true,
          render: (cell, row) => formatActorsHash(cell, row, 'services'),
        },
      ],
    },
    {
      title: () => (
        <>
          <HelpIconWithHover
            iconClassName="mr-2"
            toolTipText={t('auth.messages.sync_sections_perm_info')}
          />
          {capitalize(t('auth.headers.permissions', 'permissions'))}
        </>
      ),
      className: 'p-2',
      children: [
        {
          dataIndex: 'permactions_hash_data',
          key: 'actor_permactions_hash',
          title: () => capitalize(t('auth.headers.actors', 'actors')),
          // textWrap: 'word-break',
          ellipsis: true,
          className: 'p-2',
          render: (cell, row) => formatPermsHash(cell, row, 'actor'),
        },
        {
          dataIndex: 'permactions_hash_data',
          key: 'group_permactions_hash',
          title: capitalize(t('auth.headers.groups', 'groups')),
          // textWrap: 'word-break',
          ellipsis: true,
          className: 'p-2',
          render: (cell, row) => formatPermsHash(cell, row, 'group'),
        },
      ],
    },
    {
      title: () => (
        <>
          <HelpIconWithHover
            iconClassName="mr-2"
            toolTipText={t('auth.messages.sync_sections_package_info')}
          />
          {capitalize(t('auth.headers.package_sync', 'package sync'))}
        </>
      ),
      className: 'p-2',
      children: [
        {
          dataIndex: 'sync_packages_in_progress',
          key: 'sync_packages_in_progress',
          title: capitalize(t('auth.headers.in_progress', 'in progress')),
          className: 'p-2',
          width: 100,
          render: (cell) => (<span className={cell > 0 ? 'text-blue' : 'text-green'}>{cell}</span>),
        },
        {
          dataIndex: 'sync_packages_stop',
          key: 'sync_packages_stop',
          title: capitalize(t('auth.headers.failed', 'failed')),
          className: 'p-2',
          width: 100,
          render: (cell) => (<span className={cell > 0 ? 'text-red' : 'text-green'}>{cell}</span>),
        },
      ],
    },
    {
      dataIndex: 'latest_date',
      key: 'latest_date',
      title: () => (
        <>
          <HelpIconWithHover
            iconClassName="mr-2"
            toolTipText={checkLangAndGetTranslate('last_sync')}
          />
          {capitalize(t('auth.headers.date', 'last sync'))}
        </>
      ),
      render: (cell) => cell && dateFormat54origins(cell).dateWithTime,
      width: 120,
    },
    {
      dataIndex: 'uuid',
      key: 'hard_sync',
      title: () => (
        <>
          <HelpIconWithHover
            iconClassName="mr-2"
            toolTipText={checkLangAndGetTranslate('description')}
          />
          <span>Force sync</span>

        </>
      ),
      textWrap: 'word-break',
      className: 'p-2 pr-3',
      width: 150,
      render: formatHardSync,
    },
  ];

  const rowClassName = (row) => {
    if (_.get(row, 'is_available', false)) {
      return '';
    }

    return 'row-error';
  };

  const getTableData = () => {
    if (syncServicesData) {
      const newData = _.transform(
        syncServicesData,
        (acc, value, key) => acc.push({
          uuid: key,
          ...value,
        }),
        [],
      );

      const arr = newData.sort((a, b) => (b.uuid === authUUID ? 1 : -1));

      return arr;
    }

    return [];
  };

  useEffect(() => {
    if (authUUID) {
      initFunc();
    }
  }, [allServicesMap.size, authUUID]);

  return (
    <Row gutter={[0, 16]}>
      {!celeryActive && (
        <Col span={24}>
          <Alert
            message={capitalize(t('auth.notifications.celery_inactive', 'celery inactive'))}
            description={capitalize(t('auth.messages.celery_inactive'))}
            type="error"
            showIcon
          />
        </Col>
      )}
      <Col span={24}>
        <Row>
          <Col span={22}>
            {/* <h4 className="header-primary mb-0"> */}
            {/*  {capitalize(t('auth.pages.data_hash', 'data hash'))} */}
            {/*  : */}
            {/* </h4> */}

          </Col>
          <Col span={2} className="flex">
            <BaseButton
              className="ml-auto button-primary-outlined"
              size="small"
              onClick={initFunc}
            >
              <BaseIcon path={mdiRefresh} size={1} className="mr-1" />
              {capitalize(t('auth.buttons.refresh', 'refresh'))}
            </BaseButton>
          </Col>
        </Row>
        <hr className="my-6" />
        <Row>
          <Col span={24}>
            <AntTable
              data={getTableData()}
              columns={columns}
              rowKey="uuid"
              loading={fetching}
              bordered
              rowClassName={rowClassName}
            />
          </Col>
        </Row>
      </Col>
      <Modal
        title={`Force sync ${checkLangAndGetTranslate(forceSyncMode, false)}`}
        open={isModalOpen}
        onOk={forceSync}
        onCancel={setModal}
        footer={[
          <BaseButton key="back" onClick={setModal}>
            No
          </BaseButton>,
          <BaseButton type="primary" key="back" onClick={forceSync}>
            Yes
          </BaseButton>,
        ]}
      >
        {forceSyncMode === 'full' && (
        <p>{checkLangAndGetTranslate('fullDescription')}</p>
        )}
        <p>
          {checkLangAndGetTranslate('question')}
        </p>

      </Modal>
    </Row>
  );
}

export default SyncServicesPage;
