import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  Button, Card, Col, Form, Input, Row, Space, Tag,
} from 'antd';
import Icon from '@mdi/react';
import {
  mdiClose, mdiCogPlayOutline, mdiContentCopy, mdiContentSaveOutline, mdiTooltipTextOutline,
} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import IStickyBox from 'react-sticky-box';
import _ from 'lodash';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import { capitalize, textToClipboard } from '../../mainUtils';
import BaseCard from '../../_ui/BaseCard/BaseCard';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';
import BaseTag from '../../_ui/BaseTag/BaseTag';

function PermissionInfo({
  permission,
  editEnabled,
  params,
  onChangeParams,
  paramsError,
  resetPermissions,
  onUpdatePermissions,
  hasUnsavedData,
  permissionTableType,
}) {
  const { t } = useTranslation();
  const [permissionForm] = Form.useForm();

  const {
    permaction_uuid: uuid, title, description, perm_type: type, actor_uuid: actorUUID,
  } = permission;

  const tagType = () => {
    const colors = {
      simple: 'gray',
      check: 'volcano',
    };

    return (
      <BaseTag className={`tag-${colors[type]} ml-2`}>
        {capitalize(type)}
      </BaseTag>
    );
  };

  const defaultParams = JSON.stringify(_.get(permission, 'default_params', params), null, 4);
  const simpleParams = JSON.stringify({ status: 0 }, null, 4);
  const currentParams = JSON.stringify(_.get(permission, 'params', ''), null, 4);

  const onUseSimple = () => {
    onChangeParams(simpleParams);
    permissionForm.setFieldsValue({ params: simpleParams });
  };

  const onSetDefault = () => {
    onChangeParams(defaultParams);
    permissionForm.setFieldsValue({ params: defaultParams });
  };

  const onCancel = () => {
    onChangeParams(currentParams);
    permissionForm.setFieldsValue({ params: currentParams });
  };

  const getBorderClass = () => {
    switch (permissionTableType) {
      case 'actor':
        return 'border-purple';
      case 'group':
        return 'border-volcano';
      case 'default':
        return 'border-green';
      default:
        return '';
    }
  };

  useEffect(() => {
    permissionForm.setFieldsValue({
      params: currentParams,
    });
  }, [uuid, actorUUID]);

  return (
    <IStickyBox offsetTop={16} offsetBottom={16}>
      <BaseCard className={`${getBorderClass()}`}>
        <Row gutter={[16, 0]} wrap={false}>
          <Col flex="none">
            <h4 className="header-primary">
              {capitalize(t('auth.headers.permission_info', 'permission info'))}
            </h4>
          </Col>
          <Col flex="auto" className="flex justify-end">
            <Space size={[5, 1]} wrap className="justify-end">
              <BaseButton
                className="button-secondary-outlined"
                size="small"
                onClick={resetPermissions}
                disabled={!hasUnsavedData}
              >
                <BaseIcon path={mdiClose} size={1} className="mr-1" />
                {capitalize(t('auth.buttons.cancel', 'cancel'))}
              </BaseButton>
              <BaseButton
                className="button-primary"
                size="small"
                onClick={onUpdatePermissions}
                disabled={!hasUnsavedData}
              >
                <BaseIcon path={mdiContentSaveOutline} size={1} className="mr-1" />
                {capitalize(t('auth.buttons.save', 'save'))}
              </BaseButton>
            </Space>
          </Col>
        </Row>
        <hr className="my-6" />
        <Row>
          <Col span={24} className="flex items-center">
            <h3 className="header-primary m-0">
              {title}
            </h3>
            {tagType()}
          </Col>
          <Col span={24} className="flex items-center mt-2">
            <h5 className="header-primary">
              UUID:
              {' '}
              {uuid}
            </h5>
            <BaseButton
              className="button-primary-link ml-2"
              size="small"
              onClick={() => textToClipboard(uuid)}
            >
              <BaseIcon path={mdiContentCopy} size={1} />
            </BaseButton>
          </Col>
        </Row>
        <hr className="mt-6 mb-0" />
        <InfoBlock
          iconPath={mdiTooltipTextOutline}
          title={capitalize(t('auth.headers.description', 'description'))}
        >
          {description}
        </InfoBlock>
        {type === 'check' && (
          <InfoBlock
            iconPath={mdiCogPlayOutline}
            title={capitalize(t('auth.headers.condition', 'condition'))}
          >
            <Row gutter={[0, 5]}>
              <Col span={24}>
                <Space size={[5, 1]} wrap className="justify-end">
                  <BaseButton
                    className="button-primary-outlined"
                    size="small"
                    disabled={!editEnabled || defaultParams.trim() === params.trim()}
                    onClick={onSetDefault}
                  >
                    {capitalize(t('auth.buttons.set_default', 'set default value'))}
                  </BaseButton>
                  <BaseButton
                    className="button-primary-outlined"
                    size="small"
                    disabled={!editEnabled || simpleParams.trim() === params.trim()}
                    onClick={onUseSimple}
                  >
                    {capitalize(t('auth.buttons.use_simple', 'use simple'))}
                  </BaseButton>
                  <BaseButton
                    className="button-warning-outlined"
                    size="small"
                    disabled={!editEnabled || currentParams.trim() === params.trim()}
                    onClick={onCancel}
                  >
                    {capitalize(t('auth.buttons.cancel', 'cancel'))}
                  </BaseButton>
                </Space>
              </Col>
              <Col span={24}>
                <Form
                  initialValues={{ params }}
                  form={permissionForm}
                  onValuesChange={({ params }) => onChangeParams(params)}
                >
                  <Form.Item
                    name="params"
                    rules={[
                      () => (
                        {
                          validator: () => (paramsError ? Promise.reject(capitalize(t(
                            'auth.validation.messages.json_invalid',
                            'JSON format invalid',
                          ))) : Promise.resolve()),
                        }
                      ),
                    ]}
                  >
                    <Input.TextArea
                      autoSize={{
                        minRows: 3,
                        maxRows: 20,
                      }}
                      // value={params}
                      disabled={!editEnabled}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </InfoBlock>
        )}
      </BaseCard>
    </IStickyBox>

  );
}

export default PermissionInfo;

PermissionInfo.propTypes = {
  editEnabled: PropTypes.bool,
  hasUnsavedData: PropTypes.bool,
  onChangeParams: PropTypes.func.isRequired,
  onUpdatePermissions: PropTypes.func.isRequired,
  params: PropTypes.string,
  paramsError: PropTypes.bool,
  permission: PropTypes.object.isRequired,
  permissionTableType: PropTypes.string.isRequired,
  resetPermissions: PropTypes.func.isRequired,
};
