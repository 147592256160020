import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import IStickyBox from 'react-sticky-box';
import {
  Button, Card, Col, Form, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { antNotification, capitalize } from '../../mainUtils';
import InterfaceForm from './InterfaceForm';
import { ApiContext } from '../../api/ApiContextProvider';
import useCustomState from '../../hooks/useCustomState';
import BaseCard from '../../_ui/BaseCard/BaseCard';
import BaseButton from '../../_ui/BaseButton/BaseButton';

function InterfaceCreate({
  resetView,
  onSuccessCreate,
}) {
  const { t } = useTranslation();
  const [interfaceForm] = Form.useForm();
  const { requestCreateInterface, requestSetPublicInterfaceForActor } = useContext(ApiContext);

  const initInterfaceData = {
    interfaceIcon: 'mdi-cube',
    interfaceColor: 'DARK',
    localActors: [],
  };

  const {
    interfaceIcon,
    interfaceColor,
    localActors,
    changeState,
  } = useCustomState({ ...initInterfaceData });

  const setPublicInterfaceForActor = (actorUUID, publicInterfacesIDs) => (
    requestSetPublicInterfaceForActor({
      actorUUID,
      publicInterfacesIDs,
    })
  );

  const updatePIforGroups = (id) => {
    for (let i = 0; i < localActors.length; i++) {
      setPublicInterfaceForActor(localActors[i].uuid, [id]);
    }
  };

  const onSubmitForm = async () => {
    try {
      const formData = await interfaceForm.validateFields();

      const data = {
        ...formData,
        service_icon: interfaceIcon,
        icon_color: interfaceColor,
        params: JSON.parse(formData.params),
      };

      requestCreateInterface(data).then((response) => {
        antNotification.success(
          capitalize(t('auth.notifications.interface_created', 'interface created successful')),
        );

        interfaceForm.resetFields();
        changeState(initInterfaceData);

        resetView();

        updatePIforGroups(response?.public_interface?.id);

        if (onSuccessCreate) {
          onSuccessCreate(response);
        }
      });
    } catch (error) {
      antNotification.error(capitalize(
        t(
          'auth.notifications.invalid_data',
          'invalid data entered',
        ),
      ));
    }
  };

  const onSaveCallBackAndSetLocalActors = (actors) => {
    const { selectedActors } = actors || {};

    changeState({ localActors: selectedActors });
  };

  const initFunc = () => {
    interfaceForm.setFieldsValue({
      params: 'null',
    });
  };

  useEffect(() => {
    initFunc();
  }, []);

  return (
    <IStickyBox offsetTop={16} offsetBottom={16}>
      <BaseCard>
        <Row>
          <Col span={24}>
            <h4 className="header-primary">
              {capitalize(t('auth.headers.create_interface', 'creating new interface'))}
            </h4>
          </Col>
        </Row>
        <hr className="mt-6 mb-0" />
        <InterfaceForm
          form={interfaceForm}
          interfaceIcon={interfaceIcon}
          interfaceColor={interfaceColor}
          changeState={changeState}
          onSaveCallBackAndSetLocalActors={onSaveCallBackAndSetLocalActors}
          editMode
          groupsForPI={localActors}
        />
        <hr className="my-6" />
        <Row>
          <Col className="mr-auto flex">
            <Form form={interfaceForm}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldsValue, getFieldsError }) => {
                  const { service_name, service_domain } = getFieldsValue() || {};

                  return (
                    <BaseButton
                      className="button-primary"
                      onClick={onSubmitForm}
                      disabled={
                            !service_name
                            || !service_domain
                            || getFieldsError().filter(({ errors }) => errors.length).length > 0
                          }
                    >
                      {capitalize(t('auth.buttons.create_interface', 'create interface'))}
                    </BaseButton>
                  );
                }}
              </Form.Item>
            </Form>
            <BaseButton
              className="button-secondary-outlined ml-2"
              onClick={resetView}
            >
              {capitalize(t('auth.buttons.cancel', 'cancel'))}
            </BaseButton>
          </Col>
        </Row>
      </BaseCard>
    </IStickyBox>
  );
}

export default InterfaceCreate;

InterfaceCreate.propTypes = {
  onSuccessCreate: PropTypes.func,
  resetView: PropTypes.func.isRequired,
};
