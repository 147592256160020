import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApiContext } from '../../api/ApiContextProvider';
import { gertCertificates, gertCertificatesMap } from '../selectors';
import CertificatesList from './CertificatesList/CertificatesList';
import CertificateCreate from './CertificateCreate';
import CertificateInfo from './CertificateInfo';

function CertificatesPage() {
  const { t } = useTranslation();

  const { requestGetCertificates, requestGetAllServices } = useContext(ApiContext);

  const certificates = useSelector(gertCertificates);
  const certificatesMap = useSelector(gertCertificatesMap);

  const [currentView, setCurrentView] = useState(null);
  const [selectedCertificateID, setSelectedCertificateID] = useState('');

  const switchView = (view, id) => {
    setCurrentView(view || '');
    setSelectedCertificateID(id || null);
  };

  const isCreateView = currentView === 'create';
  const isInfoView = currentView === 'info';
  // const isInfoView = true;

  const initFunc = async () => {
    await requestGetCertificates();
    await requestGetAllServices();
  };

  const onSuccessDelete = () => {
    switchView();
    initFunc();
  };

  const onSuccessCreate = () => {
    switchView();
    initFunc();
  };

  useEffect(() => {
    initFunc();
  }, []);

  return (
    <Row gutter={[24, 16]}>
      <Col span={14}>
        <CertificatesList
          certificates={certificates}
          afterDeleteCertificate={onSuccessDelete}
          isCreateView={isCreateView}
          switchView={switchView}
          selectedCertificateID={selectedCertificateID}
        />
      </Col>
      {isCreateView && (
        <Col span={10}>
          <CertificateCreate
            switchView={switchView}
            onSuccessDelete={onSuccessDelete}
            onSuccessCreate={onSuccessCreate}
          />
        </Col>
      )}
      {isInfoView && selectedCertificateID && (
        <Col span={10}>
          <CertificateInfo
            certificate={certificatesMap.get(+selectedCertificateID)}
            onSuccessDelete={onSuccessDelete}
          />
        </Col>
      )}
    </Row>
  );
}

export default CertificatesPage;
